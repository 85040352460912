import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faCopy, faCheck, faChevronUp, faChevronDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';
import { SmartEditButton } from './index';
import CodeMirrorEditor from '../CodeMirrorEditor';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * NoteSectionEditor component for displaying and editing note sections
 * @param {Object} props - Component props
 * @param {string} props.sectionName - The section identifier (e.g., 'hpi', 'ros', 'physical_exam', 'reevaluation')
 * @param {string} props.displayTitle - Display title for the section
 * @param {string} props.subtitle - Optional subtitle with additional information
 * @param {string} props.content - The section content
 * @param {string} props.placeholder - Optional placeholder text when content is empty
 * @param {boolean} props.copied - Whether the section content has been copied
 * @param {boolean} props.isLoading - Whether the section is loading
 * @param {boolean} props.showMacroDropdown - Whether the macro dropdown is visible
 * @param {Function} props.setShowMacroDropdown - Function to update macro dropdown visibility
 * @param {Function} props.updateSection - Function to update the section content
 * @param {Function} props.saveDocument - Function to save the document
 * @param {Function} props.copyToClipboard - Function to copy content to clipboard
 * @param {Function} props.getMacrosForSection - Function to get macros for the section
 * @param {Function} props.insertSelectedMacro - Function to insert a selected macro
 * @param {Function} props.handleCreateMacro - Function to create a new macro
 * @param {Object} props.recordingManager - The recording manager instance
 * @param {Object} props.chart - The current chart data
 * @param {Function} props.getAccessTokenSilently - Auth0 function to get access token
 * @param {Object} props.protectedResources - API endpoints configuration
 * @param {Object} props.isRecording - Object tracking recording state for each section
 * @param {Function} props.setIsRecording - Function to update the isRecording state
 * @param {string|null} props.currentRecordingSection - Currently recording section, if any
 * @param {Function} props.setCurrentRecordingSection - Function to update the currentRecordingSection state
 * @param {Object} props.sectionLoadingStates - Object tracking loading state for each section
 * @param {Function} props.setSectionLoadingStates - Function to update the sectionLoadingStates
 * @param {boolean} props.useCodeMirror - Whether to use CodeMirror editor instead of TextareaAutosize
 * @param {boolean} props.isMIPSModeEnabled - Whether MIPS mode is enabled (for reevaluation section)
 * @param {boolean} props.showBorder - Whether to show a border at the top of the section
 * @param {React.RefObject} props.dropdownRef - External ref for the dropdown container (for click-outside detection)
 */
const NoteSectionEditor = ({
    sectionName,
    displayTitle,
    subtitle,
    content,
    placeholder,
    copied,
    isLoading,
    showMacroDropdown,
    setShowMacroDropdown,
    updateSection,
    saveDocument,
    copyToClipboard,
    getMacrosForSection,
    insertSelectedMacro,
    handleCreateMacro,
    recordingManager,
    chart,
    isRecording,
    setIsRecording,
    currentRecordingSection,
    setCurrentRecordingSection,
    sectionLoadingStates,
    setSectionLoadingStates,
    useCodeMirror = false,
    isMIPSModeEnabled = false,
    showBorder = true,
    dropdownRef = null
}) => {
    // Create an internal ref if no external ref is provided
    const internalDropdownRef = useRef(null);

    // Use the external ref if provided, otherwise use the internal ref
    const macroDropdownRef = dropdownRef || internalDropdownRef;

    return (
        <div className={`flex flex-col ${showBorder ? 'border-t border-gray-300 mt-2' : ''}`}>
            <div className={`flex items-center ${showBorder ? 'mt-2' : ''}`}>
                <div className="flex items-baseline">
                    <div className="font-bold text-slate-700 text-md">{displayTitle}</div>
                    {subtitle && (
                        <div className="ml-2 text-xs text-gray-500 italic">
                            {subtitle}
                        </div>
                    )}
                </div>
                <div className="flex items-center space-x-2 pl-4">
                    <button
                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                        onClick={() => saveDocument(sectionName)}
                    >
                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                    </button>
                    {copied ? (
                        <button
                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                            onClick={() => copyToClipboard(content, sectionName)}
                        >
                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                        </button>
                    ) : (
                        <button
                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                            onClick={() => copyToClipboard(content, sectionName)}
                        >
                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                        </button>
                    )}
                    {/* Macro Dropdown Button */}
                    <div className="relative" ref={macroDropdownRef}>
                        <button
                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                            onClick={() => setShowMacroDropdown(!showMacroDropdown)}
                        >
                            Macros{' '}
                            <FontAwesomeIcon
                                icon={showMacroDropdown ? faChevronUp : faChevronDown}
                                className="ml-2"
                            />
                        </button>

                        {/* Macro Dropdown */}
                        {showMacroDropdown && (
                            <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg mt-2 w-48">
                                <div className="py-1">
                                    {getMacrosForSection(sectionName).map((macro) => (
                                        <button
                                            key={macro.macro_id}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            onClick={() => {
                                                insertSelectedMacro(macro, sectionName);
                                                setShowMacroDropdown(false);
                                            }}
                                        >
                                            {macro.title}
                                        </button>
                                    ))}
                                    {getMacrosForSection(sectionName).length === 0 && (
                                        <div className="text-center">
                                            <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                            <div className="mt-6">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                    onClick={handleCreateMacro}
                                                >
                                                    + New Macro
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <SmartEditButton
                        section={sectionName}
                        label={displayTitle}
                        recordingManager={recordingManager}
                        chart={chart}
                        updateSection={updateSection}
                        isRecording={isRecording}
                        setIsRecording={setIsRecording}
                        currentRecordingSection={currentRecordingSection}
                        setCurrentRecordingSection={setCurrentRecordingSection}
                        sectionLoadingStates={sectionLoadingStates}
                        setSectionLoadingStates={setSectionLoadingStates}
                    />
                </div>
            </div>
            {isLoading ? (
                <div className="flex justify-center items-center h-full">
                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                </div>
            ) : (
                <>
                    {useCodeMirror && isMIPSModeEnabled ? (
                        <CodeMirrorEditor
                            textContent={content}
                            updateTextContent={(newContent) => updateSection(sectionName, newContent)}
                            saveDocument={saveDocument}
                        />
                    ) : (
                        <div className="relative">
                            <TextareaAutosize
                                className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                value={content || ''}
                                onChange={(e) => updateSection(sectionName, e.target.value)}
                                onBlur={() => saveDocument(sectionName, content)}
                                rows={10}
                            />
                            {(!content || content.trim() === '') && placeholder && (
                                <div className="absolute top-0 left-0 p-2 text-gray-400 pointer-events-none">
                                    {placeholder}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default NoteSectionEditor; 