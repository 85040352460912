import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';
import { getGroupHierarchy, getGroupSetting, updateGroupSetting } from '../api/organizationPortalApi';

// List of settings that can be managed from this page
const MANAGEABLE_SETTINGS = [
    {
        name: 'hide_risk_score_button',
        displayName: 'Hide Risk Score Button',
        description: 'When enabled, the risk score button will be hidden in the UI for all users in this group.',
        type: 'boolean'
    },
    {
        name: 'hide_supplemental_documents',
        displayName: 'Hide Supplemental Documents',
        description: 'When enabled, supplemental documents will be hidden in the UI for all users in this group.',
        type: 'boolean'
    },
    {
        name: 'hide_mdm_assistant',
        displayName: 'Hide MDM Assistant',
        description: 'When enabled, the MDM assistant will be hidden in the UI for all users in this group.',
        type: 'boolean'
    },
    {
        name: 'patient_experience_feedback',
        displayName: 'Patient Experience Feedback',
        description: 'When enabled, patient experience feedback will be calculated and displayed in the UI',
        type: 'boolean'
    },
    {
        name: 'risk_alerting_back_pain_chest_pain_dizziness',
        displayName: 'Risk Alerting for Back Pain, Chest Pain, and Dizziness',
        description: 'When enabled, risk alerting for back pain, chest pain, and dizziness will be applied',
        type: 'boolean'
    },
    {
        name: 'sepsis_alerting',
        displayName: 'Sepsis Alerting',
        description: 'When enabled, sepsis alerting will be applied',
        type: 'boolean'
    },
    {
        name: 'critical_care_alerting',
        displayName: 'Critical Care Alerting',
        description: 'When enabled, critical care alerting will be applied',
        type: 'boolean'
    }

    // Add more settings here as needed
];

const OrganizationPortalSettingsManagement = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();

    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [groupSettings, setGroupSettings] = useState({});
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);
    const [isLoadingSettings, setIsLoadingSettings] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [savingSettingName, setSavingSettingName] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Fetch groups for the organization
    useEffect(() => {
        const fetchGroups = async () => {
            if (!orgId) return;

            try {
                setIsLoadingGroups(true);
                const hierarchy = await getGroupHierarchy(getAccessTokenSilently, orgId);
                // Process the hierarchy to create a flattened list with proper indentation
                const flattenedGroups = [];
                const processHierarchy = (items, level = 0) => {
                    items.forEach(item => {
                        flattenedGroups.push({
                            ...item,
                            displayName: '  '.repeat(level) + item.name
                        });
                        if (item.children && item.children.length > 0) {
                            processHierarchy(item.children, level + 1);
                        }
                    });
                };

                processHierarchy(hierarchy);

                // Filter groups based on organization ID
                const processedGroups = flattenedGroups.filter(group => {
                    // For org_id 51, only show specific groups
                    if (Number(orgId) === 51) {
                        // Filter out top-level groups (level === 1)
                        return group.level !== 1;
                    }

                    // For other organizations, show all groups
                    return true;
                });

                setGroups(processedGroups);

                // Select the first group by default if none is selected
                if (!selectedGroupId && processedGroups.length > 0) {
                    setSelectedGroupId(processedGroups[0].group_id);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            } finally {
                setIsLoadingGroups(false);
            }
        };

        fetchGroups();
    }, [getAccessTokenSilently, orgId, selectedGroupId]);

    // Fetch settings for the selected group
    const fetchGroupSettings = useCallback(async () => {
        if (!selectedGroupId) return;

        try {
            setIsLoadingSettings(true);
            setErrorMessage('');

            const settings = {};

            // Fetch each manageable setting for the selected group
            for (const setting of MANAGEABLE_SETTINGS) {
                const response = await getGroupSetting(getAccessTokenSilently, selectedGroupId, setting.name);

                settings[setting.name] = {
                    value: response.Setting?.value
                };
            }

            setGroupSettings(settings);
        } catch (error) {
            console.error('Error fetching group settings:', error);
            setErrorMessage('Failed to load settings. Please try again.');
        } finally {
            setIsLoadingSettings(false);
        }
    }, [getAccessTokenSilently, selectedGroupId]);

    // Load settings when group changes
    useEffect(() => {
        if (selectedGroupId) {
            fetchGroupSettings();
        }
    }, [selectedGroupId, fetchGroupSettings]);

    // Handle group selection change
    const handleGroupChange = (e) => {
        setSelectedGroupId(e.target.value);
    };

    // Handle setting change and save automatically
    const handleSettingChange = async (settingName, value) => {
        if (!selectedGroupId) return;

        // Update local state immediately for responsive UI
        setGroupSettings(prev => ({
            ...prev,
            [settingName]: {
                ...prev[settingName],
                value
            }
        }));

        // Save to backend
        try {
            setIsSaving(true);
            setSavingSettingName(settingName);
            setErrorMessage('');
            setSuccessMessage('');

            // Call the API to update the setting
            await updateGroupSetting(
                getAccessTokenSilently,
                selectedGroupId,
                settingName,
                value
            );

            // Show success message briefly
            setSuccessMessage(`Setting "${MANAGEABLE_SETTINGS.find(s => s.name === settingName).displayName}" updated successfully.`);

            // Clear success message after a delay
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);

        } catch (error) {
            console.error(`Error saving setting ${settingName}:`, error);
            setErrorMessage(`Failed to save setting. ${error.message || 'Please try again later.'}`);

            // Optionally revert the local state change on error
            // This would require keeping track of the previous value
        } finally {
            setIsSaving(false);
            setSavingSettingName(null);
        }
    };

    // Render a setting based on its type
    const renderSetting = (setting) => {
        const settingData = groupSettings[setting.name];

        if (!settingData) return null;

        const isCurrentlySaving = isSaving && savingSettingName === setting.name;

        switch (setting.type) {
            case 'boolean':
                return (
                    <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow mb-4">
                        <div className="flex-1">
                            <div className="font-medium text-gray-900">{setting.displayName}</div>
                            <div className="text-sm text-gray-500 mt-1">{setting.description}</div>
                        </div>
                        <div className="flex items-center">
                            {isCurrentlySaving && (
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-600 mr-3"></div>
                            )}
                            <label className="inline-flex items-center cursor-pointer">
                                <span className="relative">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={settingData.value === true}
                                        onChange={(e) => handleSettingChange(setting.name, e.target.checked)}
                                        disabled={isCurrentlySaving}
                                    />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-indigo-600"></div>
                                </span>
                            </label>
                        </div>
                    </div>
                );
            default:
                return (
                    <div className="p-4 bg-white rounded-lg shadow mb-4">
                        <div className="flex justify-between items-center">
                            <div>
                                <div className="font-medium text-gray-900 flex items-center">
                                    {setting.displayName}
                                    {isCurrentlySaving && (
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-600 ml-2"></div>
                                    )}
                                </div>
                                <div className="text-sm text-gray-500 mt-1">{setting.description}</div>
                            </div>
                        </div>
                    </div>
                );
        }
    };

    return (
        <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Group Settings Management</h1>
                    <p className="mt-2 text-sm text-gray-600">
                        Configure settings for specific groups in your organization.
                    </p>
                </div>

                {/* Success and Error Messages */}
                {successMessage && (
                    <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">{successMessage}</p>
                            </div>
                        </div>
                    </div>
                )}

                {errorMessage && (
                    <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-red-800">{errorMessage}</p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Group Selection */}
                <div className="bg-white rounded-lg shadow mb-8">
                    <div className="p-6">
                        <div className="mb-6">
                            <label htmlFor="group-select" className="block text-sm font-medium text-gray-700 mb-1">
                                Select Group
                            </label>
                            <div className="relative">
                                <select
                                    id="group-select"
                                    value={selectedGroupId || ''}
                                    onChange={handleGroupChange}
                                    disabled={isLoadingGroups || isLoadingSettings}
                                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                >
                                    <option value="" disabled>Select a group</option>
                                    {groups.map((group) => (
                                        <option key={group.group_id} value={group.group_id}>
                                            {group.displayName}
                                        </option>
                                    ))}
                                </select>
                                {isLoadingSettings && (
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-600"></div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Settings */}
                        {isLoadingGroups ? (
                            <div className="flex justify-center items-center py-8">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                            </div>
                        ) : groups.length === 0 ? (
                            <div className="text-center py-8 text-gray-500">
                                No groups found for this organization.
                            </div>
                        ) : selectedGroupId ? (
                            <div>
                                <h3 className="text-lg font-medium text-gray-900 mb-4">Group Settings</h3>
                                {isLoadingSettings ? (
                                    <div className="flex justify-center items-center py-8">
                                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                                    </div>
                                ) : (
                                    <div>
                                        {MANAGEABLE_SETTINGS.map((setting) => (
                                            <div key={setting.name}>
                                                {renderSetting(setting)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-500">
                                Please select a group to manage settings.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationPortalSettingsManagement; 