import React from 'react';
import { Dialog } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

const consentAttestations = {
    single_patient: "The patient provided consent for the use of Ambient AI technology.",
    multi_parties: "I obtained consent from all parties for the use of Ambient AI technology.",
    incapacitated: "Due to the patient’s medical condition they were unable to provide consent for the use of Ambient AI technology; however, I determined that the use of Ambient AI technology was warranted given the complexity of the case and their presentation for emergency services."
};

const ConsentModal = ({ isOpen, onClose, onSelect }) => {
    const [selectedOption, setSelectedOption] = React.useState('');
    const [showCopied, setShowCopied] = React.useState(false);

    const handleOptionSelect = (value) => {
        setSelectedOption(value);
        onSelect(consentAttestations[value]);
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
            onClose();
        }, 1000);
    };

    return (
        <Dialog
            as="div"
            className="fixed inset-0 flex items-center justify-center"
            open={isOpen}
            onClose={onClose}
        >
            <Dialog.Panel className="w-full max-w-md rounded-lg bg-white p-6 text-left shadow-xl">
                {!showCopied ? (
                    <>
                        <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900 mb-6">
                            Consent Attestation
                        </Dialog.Title>

                        <div className="mb-6">
                            <p className="text-gray-700 mb-4">
                                Please select the most relevant attestation.
                            </p>

                            <div className="space-y-4">
                                <label
                                    className="flex items-center space-x-3 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors"
                                    onClick={() => handleOptionSelect('single_patient')}
                                >
                                    <input
                                        type="radio"
                                        name="consent"
                                        value="single_patient"
                                        checked={selectedOption === 'single_patient'}
                                        onChange={() => { }}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <span className="text-gray-900">Single party state: Patient consent</span>
                                </label>

                                <label
                                    className="flex items-center space-x-3 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors"
                                    onClick={() => handleOptionSelect('incapacitated')}
                                >
                                    <input
                                        type="radio"
                                        name="consent"
                                        value="incapacitated"
                                        checked={selectedOption === 'incapacitated'}
                                        onChange={() => { }}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <span className="text-gray-900">Single party state: Unable to consent</span>
                                </label>

                                <label
                                    className="flex items-center space-x-3 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors"
                                    onClick={() => handleOptionSelect('multi_parties')}
                                >
                                    <input
                                        type="radio"
                                        name="consent"
                                        value="multi_parties"
                                        checked={selectedOption === 'multi_parties'}
                                        onChange={() => { }}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <span className="text-gray-900">All party state: All party consent</span>
                                </label>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center py-4">
                        <CheckCircleIcon className="h-12 w-12 text-green-500 mb-2" />
                        <p className="text-lg font-medium text-gray-900">Copied!</p>
                    </div>
                )}
            </Dialog.Panel>
        </Dialog>
    );
};

export default ConsentModal; 