import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave, faCopy, faCheck, faArrowCircleLeft, faCalculator, faExclamationTriangle,
    faSync, faArchive, faUndo, faChevronUp, faChevronDown, faSpinner, faTrash, faEllipsisVertical, faNotesMedical
} from '@fortawesome/free-solid-svg-icons'

import { Button } from '../components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import '../styles/ViewReports.css';

import {
    CheckCircleIcon, XMarkIcon, XCircleIcon
} from '@heroicons/react/20/solid';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import TextareaAutosize from 'react-textarea-autosize';

import {
    SmartEditButton, RiskScoreButton, RiskMessagesButton,
    CPTCodesButton, NoteFeedbackButton, NoteSectionEditor,
    MDMCodingButton, PatientExperienceButton
} from '../components/NoteViewComponents';

import recordingManager from '../contexts/RecordingManager';
import TipTapEditor from '../components/TipTap';
import '../styles/Editor.css';

import { htmlToText } from 'html-to-text';


import PatientExperienceModal from '../components/PatientExperienceModal';
import SupplementalDocumentsModal from '../components/SupplementalDocumentsModal';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

import { documentApi } from '../api/documentApi';
import ConsentModal from '../components/ConsentModal';
import { useSettings } from '../contexts/SettingsProvider';
import TreatmentPlanModal from '../components/TreatmentPlanModal';




function PatientInfoCarepoint() {
    const [hpi, setHPI] = useState('');
    const [ros, setROS] = useState('');
    const [exam, setExam] = useState('');
    const [reevaluation, setReevaluation] = useState('');
    const [procedures, setProcedures] = useState('');


    const [mdm, setMDM] = useState('');
    const [copa, setCopa] = useState(-1);
    const [complexityOfData, setComplexityOfData] = useState(-1);
    const [riskOfComplications, setRiskOfComplications] = useState(-1);
    const [showRiskScoreDropdown, setShowRiskScoreDropdown] = useState(false);
    const [showMacroDropdown, setShowMacroDropdown] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Chart...');
    const [patientName, setPatientName] = useState('');

    const [procedureDescription, setProcedureDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();  // Move hook to component level
    const [copiedHPI, setCopiedHPI] = useState(false);
    const [copiedROS, setCopiedROS] = useState(false);
    const [copiedExam, setCopiedExam] = useState(false);
    const [copiedReevaluation, setCopiedReevaluation] = useState(false);
    const [copiedProcedures, setCopiedProcedures] = useState(false);
    const [copiedMDM, setCopiedMDM] = useState(false);
    const [copiedAll, setCopiedAll] = useState(false);
    const [isEditingPatientName, setIsEditingPatientName] = useState(false);
    const [isEditingProcedureDescription, setIsEditingProcedureDescription] = useState(false);
    const location = useLocation();
    const chart = location.state?.document;
    const providedDate = location.state?.providedDate;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(chart);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [macro_list, setMacroList] = useState({});
    const riskScoreDropdownRef = useRef(null);
    const macroDropdownRef = useRef(null);
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(chart?.note_rating === null ? true : false);
    const [originalMDM, setOriginalMDM] = useState(mdm);
    const [cleanedMDM, setCleanedMDM] = useState(mdm);
    const [isRemoved, setIsRemoved] = useState(false);
    const [documentStatus, setDocumentStatus] = useState(chart?.document_status === undefined ? 'PRE_ENCOUNTER' : chart.document_status);
    const [mdmStatus, setMDMStatus] = useState(chart?.mdm_status === undefined ? 'NOT_GENERATED' : chart.mdm_status);

    const [acknowledgedPatient, setAcknowledgedPatient] = useState(null);
    const [introducedThemselves, setIntroducedThemselves] = useState(null);
    const [providedDuration, setProvidedDuration] = useState(null);
    const [providedExplanation, setProvidedExplanation] = useState(null);
    const [managedUpCareTeam, setManagedUpCareTeam] = useState(null);

    const criteria = [
        acknowledgedPatient,
        introducedThemselves,
        providedDuration,
        providedExplanation,
        managedUpCareTeam
    ];

    const hasNullCriteria = criteria.some(criterion => criterion === null);
    const totalCriteria = criteria.length;
    const completedCriteria = criteria.filter(Boolean).length;
    const completionPercentage = (completedCriteria / totalCriteria) * 100;
    const [isPatientExperienceModalOpen, setIsPatientExperienceModalOpen] = useState(false);

    const [riskMessages, setRiskMessages] = useState([]);
    const [hasRiskMessages, setHasRiskMessages] = useState(false);

    const [riskScores, setRiskScores] = useState([]);
    const [currentRiskScoreId, setCurrentRiskScoreId] = useState(null);

    const [parsedCPTProceduralCodes, setParsedCPTProceduralCodes] = useState([]);
    const [parsedCPTEMCodes, setParsedCPTEMCodes] = useState([]);

    const isCustomMDMExempt = useFeatureFlagEnabled('custom_mdm_exempt');
    const isMIPSModeEnabled = useFeatureFlagEnabled('mips_enabled');
    const isPatientExperienceEnabled = useFeatureFlagEnabled('patient_experience');
    const isPatientExperienceEnabledWebApp = useFeatureFlagEnabled('patient_experience_web_app');
    const isProceduresEnabled = useFeatureFlagEnabled('procedures');
    const isCPTCodesEnabled = useFeatureFlagEnabled('cpt_codes');
    const isUSACSAttestationEnabled = useFeatureFlagEnabled('usacs_attestation');
    const isPatientConsentEnabled = useFeatureFlagEnabled('patient_consent_workflow');
    const generateTreatmentPlanEnabled = useFeatureFlagEnabled('generate_treatment_plan');
    const USACS_ATTESTATION = "This note was generated using Cleo (ambient AI) software. If any parties were recorded to generate this note outside of the software user, their consent was obtained prior to recording their voices. All portions of this note were verified by the clinician prior to inclusion in the medical record.";
    // State variables for macro dropdown visibility
    const [showHpiMacroDropdown, setShowHpiMacroDropdown] = useState(false);
    const [showRosMacroDropdown, setShowRosMacroDropdown] = useState(false);
    const [showExamMacroDropdown, setShowExamMacroDropdown] = useState(false);
    const [showReevaluationMacroDropdown, setShowReevaluationMacroDropdown] = useState(false);
    const [showProceduresMacroDropdown, setShowProceduresMacroDropdown] = useState(false);
    const [showMDMMacrosDropdown, setShowMDMMacrosDropdown] = useState(false);

    const hpiMacroDropdownRef = useRef(null);
    const rosMacroDropdownRef = useRef(null);
    const examMacroDropdownRef = useRef(null);
    const reevaluationMacroDropdownRef = useRef(null);
    const proceduresMacroDropdownRef = useRef(null);
    const mdmMacrosDropdownRef = useRef(null);

    const [showForceResolveModal, setShowForceResolveModal] = useState(false);

    const actorInfo = location.state?.actorInfo || {};

    const [isRecording, setIsRecording] = useState({
        hpi: false,
        ros: false,
        mdm: false,
        physical_exam: false,
        reevaluation: false,
        procedures: false
    });

    const [currentRecordingSection, setCurrentRecordingSection] = useState(null);

    const [sectionLoadingStates, setSectionLoadingStates] = useState({
        hpi: false,
        ros: false,
        physical_exam: false,
        reevaluation: false,
        mdm: false,
        procedures: false
    });

    const [isSupplementalDocsModalOpen, setIsSupplementalDocsModalOpen] = useState(false);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    // Add a new state for delete loading
    const [isDeleting, setIsDeleting] = useState(false);

    const [showActionsMenu, setShowActionsMenu] = useState(false);

    const [isConsentModalOpen, setIsConsentModalOpen] = React.useState(false);
    const [pendingCopyContent, setPendingCopyContent] = React.useState(null);

    // Add this state variable near your other risk-related state variables
    const [riskScoreCalculatorVisible, setRiskScoreCalculatorVisible] = useState(false);

    // Add a new state to track which panel is expanded
    const [expandedPanel, setExpandedPanel] = useState(null); // 'riskMessages', 'mdmCoding', 'cptCodes', or null

    const [isTreatmentPlanModalOpen, setIsTreatmentPlanModalOpen] = useState(false);

    // Helper functions to manage panel visibility
    const expandPanel = (panelName) => {
        setExpandedPanel(panelName);
    };

    const collapsePanel = () => {
        setExpandedPanel(null);
    };

    const isPanelExpanded = (panelName) => expandedPanel === panelName;

    // Update to use getSetting directly instead of the convenience function
    const { getSetting, isLoading: isSettingsLoading } = useSettings();

    const handleDeleteConfirm = async () => {
        setIsDeleting(true);  // Start loading
        try {
            await documentApi.deleteDocument(getAccessTokenSilently, chart.document_id);
            setIsDeleteDialogOpen(false);
            navigate('/er-dashboard', { state: { providedDate: providedDate } });
        } catch (error) {
            console.error('Error deleting document:', error);
            // Handle token expiration
            if (error.message.includes('invalid_token')) {
                console.error('Token error:', error);
            } else {
                console.error('Error:', error);
            }
        } finally {
            setIsDeleting(false);  // Stop loading regardless of outcome
        }
    };

    useEffect(() => {
        if (riskMessages.length > 0 && riskMessages.some(item => !item.resolved)) {
            setHasRiskMessages(true);
        }
    }, [riskMessages]);


    const fetchRiskMessages = async () => {
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiFetchRiskMessages.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({ "document_id": chart.document_id })
                }
            );

            setRiskMessages(data.risk_items);


            if (data.risk_items.length > 0 && data.risk_items.some(item => !item.resolved)) {
                setHasRiskMessages(true);

                // Check for any risk messages where auto_show is True and resolved is False
                const hasAutoShowUnresolvedRiskMessages = data.risk_items.some(item =>
                    !item.resolved && (item.auto_show || item.force_resolve)
                );


                if (hasAutoShowUnresolvedRiskMessages) {
                    expandPanel('riskMessages');
                }
            } else {
                setHasRiskMessages(false);
            }
        } catch (error) {
            console.error('Error fetching risk messages:', error);
        }
    };



    const fetchRiskScores = async () => {
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiFetchRiskScores.endpoint,
                {
                    method: 'GET'
                }
            );

            setRiskScores(data.risk_scores);
        } catch (error) {
            console.error('Error fetching risk scores:', error);
        }
    };


    const insertSelectedMacro = async (macro) => {
        const macroSection = macro.section;
        const sectionMapping = {
            "hpi": hpi,
            "ros": ros,
            "physical_exam": exam,
            "reevaluation": reevaluation,
            "mdm": mdm,
            "procedures": procedures
        }
        const sectionToUpdate = sectionMapping[macroSection];
        const updatedSection = sectionToUpdate ? sectionToUpdate + "\n\n" + macro.content : macro.content;
        const setterMapping = {
            "hpi": setHPI,
            "ros": setROS,
            "physical_exam": setExam,
            "reevaluation": setReevaluation,
            "mdm": setMDM,
            "procedures": setProcedures
        };

        setterMapping[macroSection](updatedSection);
        saveDocument(macroSection, updatedSection);

        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: chart.document_id,
                        field_to_update: 'macro_manually_inserted',
                        new_value: true
                    })
                }
            );
        } catch (error) {
            console.error('Error updating macro_manually_inserted flag:', error);
        }
    };

    const copyAllToClipboardPlain = () => {
        const unresolvedForceResolveItems = riskMessages.filter(
            (item) => item.force_resolve === true && item.resolved === false
        );

        if (unresolvedForceResolveItems.length > 0) {
            // Show modal
            setShowForceResolveModal(true);
            return; // Do not proceed with copying
        }

        let allText = `HISTORY OF PRESENT ILLNESS: \n${hpi}`;

        // Conditionally include the ROS section
        if (!actorInfo.transfer_ros_to_hpi) {
            allText += `\n\nREVIEW OF SYSTEMS: \n${ros}`;
        }


        allText += `\n\nPHYSICAL EXAM: \n${exam}`;

        // Conditionally include the Evaluations section
        if (!actorInfo.hide_reevaluation_section) {
            allText += `\n\nEVALUATIONS: \n${reevaluation}`;
        }

        if (mdm) {
            allText += `\n\nMEDICAL DECISION-MAKING: \n${mdm}`;
        }

        if (isProceduresEnabled && procedures) {
            allText += `\n\nPROCEDURES & ATTESTATIONS: \n${procedures}`;
        }

        if (isUSACSAttestationEnabled) {
            allText += `\n\n${USACS_ATTESTATION}`;
        }

        navigator.clipboard.writeText(allText).then(() => {
            setCopiedAll(true);
            updateDocument(chart.document_id, 'copied_all', true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000); // Reset copy state after 3 seconds
        });
    };

    function convertHtmlToPlainText(html) {
        if (!html) return '';

        try {
            return htmlToText(html, {
                wordwrap: false,
                selectors: [
                    // Use 'heading' formatter for headings  
                    { selector: 'h1', format: 'heading' },
                    { selector: 'h2', format: 'heading' },
                    { selector: 'h3', format: 'heading' },
                    { selector: 'h4', format: 'heading' },
                    { selector: 'h5', format: 'heading' },
                    { selector: 'h6', format: 'heading' },

                    // Use 'lineBreak' for <br> tags  
                    { selector: 'br', format: 'lineBreak' },

                    // Use 'unorderedList' and 'orderedList' for lists  
                    { selector: 'ul', format: 'unorderedList' },
                    { selector: 'ol', format: 'orderedList' },

                    // Use 'anchor' formatter for links  
                    { selector: 'a', format: 'anchor' },

                    // Use a custom formatter for bold elements  
                    { selector: 'strong', format: 'uppercaseInline' },
                    { selector: 'b', format: 'uppercaseInline' },
                ],
                formatters: {
                    uppercaseInline: function (elem, walk, builder, formatOptions) {
                        // Create a temporary builder to collect text  
                        let textContent = '';

                        const tempBuilder = {
                            addInline(text) {
                                textContent += text;
                            },
                            addLineBreak() {
                                textContent += '\n';
                            },
                            openBlock(blockOptions) { },
                            closeBlock() { },
                            options: builder.options,
                        };

                        // Process child nodes if they exist  
                        if (elem.children && elem.children.length > 0) {
                            walk(elem.children, tempBuilder);
                        } else if (elem.text) {
                            // If no children, but text exists directly  
                            textContent += elem.text;
                        } else if (elem.data) {
                            // For text nodes  
                            textContent += elem.data;
                        } else if (elem.content) {
                            // Fallback to content property  
                            textContent += elem.content;
                        }

                        // Add the uppercase text to the main builder  
                        builder.addInline(textContent.toUpperCase());
                    },
                },
            });
        } catch (error) {
            console.warn('Conversion failed:', error);

            // Fallback to simple text stripping  
            return html
                .replace(/<br\s*\/?>/gi, '\n')
                .replace(/<\/p>/gi, '\n\n')
                .replace(/<li>/gi, '\n• ')
                .replace(/<strong>|<b>/gi, '') // Remove bold tags
                .replace(/<\/strong>|<\/b>/gi, '')
                .replace(/<[^>]+>/g, '') // Remove any remaining HTML tags
                .trim();
        }
    }



    const copyAllToClipboardHtml = () => {
        const unresolvedForceResolveItems = riskMessages.filter(
            (item) => item.force_resolve === true && item.resolved === false
        );

        if (unresolvedForceResolveItems.length > 0) {
            setShowForceResolveModal(true);
            return;
        }

        // Prepare HTML content
        let htmlContent = `<h4>HISTORY OF PRESENT ILLNESS:</h4><div>${hpi.replace(/\n/g, '<br>')}</div><br>`;

        if (!actorInfo.transfer_ros_to_hpi) {
            htmlContent += `<h4>REVIEW OF SYSTEMS:</h4><div>${ros.replace(/\n/g, '<br>')}</div><br>`;
        }

        htmlContent += `<h4>PHYSICAL EXAM:</h4><div>${exam.replace(/\n/g, '<br>')}</div><br>`;

        if (!actorInfo.hide_reevaluation_section) {
            htmlContent += `<h4>EVALUATIONS:</h4><div>${reevaluation.replace(/\n/g, '<br>')}</div><br>`;
        }

        if (mdm) {
            htmlContent += `<h4>MEDICAL DECISION-MAKING:</h4><div>${mdm.replace(/\n/g, '<br>')}</div>`;
        }

        if (isProceduresEnabled && procedures) {
            htmlContent += `<h4>PROCEDURES & ATTESTATIONS:</h4><div>${procedures.replace(/\n/g, '<br>')}</div><br>`;
        }

        // Add attestation if enabled
        if (isUSACSAttestationEnabled) {
            htmlContent += `<div>${USACS_ATTESTATION}</div>`;
        }

        if (isPatientConsentEnabled) {
            setPendingCopyContent(htmlContent);
            setIsConsentModalOpen(true);
        } else {
            // If consent workflow is not enabled, proceed with direct copy
            const plainTextContent = convertHtmlToPlainText(htmlContent);
            setCopiedAll(true);
            updateDocument(chart.document_id, 'copied_all', true);

            if (navigator.clipboard && window.isSecureContext) {
                const htmlType = 'text/html';
                const textType = 'text/plain';

                const htmlBlob = new Blob([htmlContent], { type: htmlType });
                const textBlob = new Blob([plainTextContent], { type: textType });

                const data = new ClipboardItem({
                    [htmlType]: htmlBlob,
                    [textType]: textBlob,
                });

                navigator.clipboard.write([data]).then(
                    () => {
                        console.log('Copied to clipboard successfully!');
                    },
                    (err) => {
                        console.error('Could not copy text: ', err);
                        fallbackCopyToClipboard(plainTextContent);
                    }
                );
            } else {
                fallbackCopyToClipboard(plainTextContent);
            }

            setTimeout(() => {
                setCopiedAll(false);
            }, 3000);
        }
    };

    const handleConsentSelect = (attestation) => {
        if (pendingCopyContent) {
            const contentWithAttestation = `${pendingCopyContent}<br/>${attestation}`;

            // Prepare plain text content
            const plainTextContent = convertHtmlToPlainText(contentWithAttestation);

            setCopiedAll(true);
            updateDocument(chart.document_id, 'copied_all', true);

            if (navigator.clipboard && window.isSecureContext) {
                // Use the Async Clipboard API for both HTML and plain text content
                const htmlType = 'text/html';
                const textType = 'text/plain';

                const htmlBlob = new Blob([contentWithAttestation], { type: htmlType });
                const textBlob = new Blob([plainTextContent], { type: textType });

                const data = new ClipboardItem({
                    [htmlType]: htmlBlob,
                    [textType]: textBlob,
                });

                navigator.clipboard.write([data]).then(
                    () => {
                        console.log('Copied to clipboard successfully!');
                    },
                    (err) => {
                        console.error('Could not copy text: ', err);
                        fallbackCopyToClipboard(plainTextContent);
                    }
                );
            } else {
                fallbackCopyToClipboard(plainTextContent);
            }

            setPendingCopyContent(null);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000);
        }
    };

    const fallbackCopyToClipboard = (content) => {
        const textarea = document.createElement('textarea');
        textarea.value = content; // Use plain text content
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page
        textarea.style.left = '-9999px';
        textarea.style.top = '0';

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                console.log('Fallback: Copied to clipboard successfully!');
            } else {
                console.error('Fallback: Could not copy text');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textarea);
    };

    // Define a common section configuration for both updateSection and saveDocument
    const getSectionConfig = () => ({
        'hpi': { setter: setHPI, field: 'hpi', value: hpi },
        'ros': { setter: setROS, field: 'ros', value: ros },
        'physical_exam': { setter: setExam, field: 'physical_exam', value: exam },
        'reevaluation': { setter: setReevaluation, field: 'reevaluation', value: reevaluation },
        'procedures': { setter: setProcedures, field: 'procedures', value: procedures },
        'mdm': { setter: setMDM, field: 'mdm', value: mdm },
        'procedure_description': { setter: setProcedureDescription, field: 'procedure_description', value: procedureDescription },
        'patient_name': { setter: setPatientName, field: 'patient_name', value: patientName },
    });

    const updateSection = (sectionName, sectionContent) => {
        // Get the configuration for the specified section
        const sectionConfig = getSectionConfig();
        const config = sectionConfig[sectionName];

        if (config) {
            // Update the state using the appropriate setter
            config.setter(sectionContent);

            // Update the editedDocument state
            setEditedDocument(prev => ({ ...prev, [config.field]: sectionContent }));
        } else {
            console.warn(`Unknown section name: ${sectionName}`);
        }
    };

    const saveDocument = async (fieldToSave, newValue) => {
        // Handle the case where fieldToSave is a function (from onClick handlers)
        if (typeof fieldToSave === 'function') {
            fieldToSave();
            return;
        }

        // Get the section configuration
        const sectionConfig = getSectionConfig();

        // If newValue is not provided, get it from the current state
        if (newValue === undefined) {
            // Check if the field exists in our mapping
            if (fieldToSave in sectionConfig) {
                newValue = sectionConfig[fieldToSave].value;
            } else {
                // For fields not in our mapping, try to get from chart directly
                // This handles cases where fieldToSave might be used in other components
                if (fieldToSave in chart) {
                    // In this case, we can't determine changes since we don't have the current state
                    // We'll proceed with the save operation using the chart value
                    newValue = chart[fieldToSave];
                } else {
                    console.log('Invalid field to save:', fieldToSave);
                    return;
                }
            }
        }

        // Check if there are any changes to save
        const currentValue = chart[fieldToSave];
        const hasChanges = currentValue !== newValue;

        if (!hasChanges) {
            console.log("No changes to save.");
            return; // Exit if no changes
        }

        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: chart.document_id,
                        field_to_update: fieldToSave,
                        new_value: newValue
                    })
                }
            );

            if (data.updated_document) {
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        } catch (error) {
            console.error('Error updating document:', error);
            setErrorMessage("An error occurred while updating the document.");
            setShowErrorNotification(true);
            setTimeout(() => setShowErrorNotification(false), 5000);
        }
    };

    const parseMDMCoding = (mdmCoding) => {
        // Initialize default values
        let copa = -1;
        let complexityOfData = -1;
        let riskOfComplications = -1;

        // Split the mdmCoding string by commas to get individual parts
        const parts = mdmCoding.split(', ');

        // Parse each part to extract the integer values
        parts.forEach(part => {
            const [key, value] = part.split(': ');
            const intValue = parseInt(value.trim());
            if (key.trim() === 'copa') {
                copa = intValue;
            } else if (key.trim() === 'complexity_of_data') {
                complexityOfData = intValue;
            } else if (key.trim() === 'risk_of_complications') {
                riskOfComplications = intValue;
            }
        });

        setCopa(copa);
        setComplexityOfData(complexityOfData);
        setRiskOfComplications(riskOfComplications);
    };

    // Replace the approveDocument function with this new toggleArchiveStatus function
    const toggleArchiveStatus = async () => {
        try {
            const newStatus = chart.document_status === "FINALIZED" ? "ENCOUNTER_COMPLETE" : "FINALIZED";

            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: chart.document_id,
                        field_to_update: "document_status",
                        new_value: newStatus
                    })
                }
            );

            setDocumentStatus(newStatus);

            if (newStatus === "FINALIZED") {
                navigate('/er-dashboard', { state: { providedDate: providedDate } });
            }
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    const generateMDM = async () => {
        setLoadingMessage("Generating MDM...");
        setMDMStatus("IN_PROGRESS");
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiCalculateMDM.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({ "document_id": chart.document_id })
                }
            );

            setMDM(data.mdm_content);
            parseMDMCoding(data.mdm_coding);

            setMDMStatus("GENERATED");
            setIsRemoved(false);
            expandPanel('mdmCoding');

            await fetchRiskMessages();
        } catch (error) {
            console.error('Error calculating MDM:', error);
            setMDMStatus("ERROR");
        }
    };

    const fetchDocument = useCallback(async () => {
        setLoadingMessage("Fetching Chart...");
        setIsLoading(true);
        try {
            // Fetch document and macros in parallel
            const [documentData, macrosData] = await Promise.all([
                callProtectedApi(
                    getAccessTokenSilently,
                    protectedResources.apiGetDocument.endpoint,
                    {
                        method: 'POST',
                        body: JSON.stringify({ document_id: chart.document_id })
                    }
                ),
                callProtectedApi(
                    getAccessTokenSilently,
                    protectedResources.apiGetMacros.endpoint,
                    {
                        method: 'POST'
                    }
                )
            ]);

            if (documentData.document) {
                console.log("PROCEDURES", documentData.document.procedures);
                setHPI(documentData.document.hpi);
                setROS(documentData.document.ros);
                setExam(documentData.document.physical_exam);
                setReevaluation(documentData.document.reevaluation);
                setProcedures(documentData.document.procedures);
                setMDM(documentData.document.mdm);
                setPatientName(documentData.document.patient_name);
                setDocumentStatus(documentData.document.document_status);
                setProcedureDescription(documentData.document.procedure_description);

                // Add these lines to update patient experience criteria
                setAcknowledgedPatient(documentData.document.acknowledged_patient);
                setIntroducedThemselves(documentData.document.introduced_themselves);
                setProvidedDuration(documentData.document.provided_duration);
                setProvidedExplanation(documentData.document.provided_explanation);
                setManagedUpCareTeam(documentData.document.managed_up_care_team);



                // Set CPT codes if they exist
                if (documentData.document.code_suggestions) {
                    const cptCodes = JSON.parse(documentData.document.code_suggestions);

                    // Set EM codes
                    if (cptCodes.em_codes && Array.isArray(cptCodes.em_codes)) {
                        setParsedCPTEMCodes(
                            cptCodes.em_codes.map(code => ({
                                id: uuidv4(),
                                text: `${code.code}: ${code.description}`
                            }))
                        );
                    }

                    // Set procedural codes
                    if (cptCodes.procedural_codes && Array.isArray(cptCodes.procedural_codes)) {
                        setParsedCPTProceduralCodes(
                            cptCodes.procedural_codes.map(code => ({
                                id: uuidv4(),
                                text: `${code.code}: ${code.description}`
                            }))
                        );
                    }
                }

                if (documentData.document.mdm_coding) {
                    parseMDMCoding(documentData.document.mdm_coding);
                }
                setMDMStatus(documentData.document.mdm_status);
            } else {
                setHPI("");
                setROS("");
                setExam("");
                setReevaluation("");
                setMDM('');
                setPatientName("");
                setProcedureDescription("");
                setParsedCPTEMCodes([]);
                setParsedCPTProceduralCodes([]);
            }

            if (macrosData.macros && typeof macrosData.macros === 'object') {
                const newMacros = Object.values(macrosData.macros).map(macro => ({
                    macro_id: macro.macro_id,
                    title: macro.title,
                    command: macro.command,
                    content: macro.content,
                    section: macro.section,
                    is_shareable_within_org: macro.is_shareable_within_org,
                    created_at: macro.created_at,
                    updated_at: macro.updated_at
                }));
                setMacroList(newMacros);
            }

            await fetchRiskMessages();
            await fetchRiskScores();
        } catch (error) {
            console.error('Error fetching document:', error);
        } finally {
            setIsLoading(false);
        }
    }, [chart.document_id]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [fetchDocument]);

    const copyToClipboard = (text, type) => {
        // Prepare HTML content without section header
        let htmlContent = `<div>${text.replace(/\n/g, '<br>')}</div>`;

        // Add USACS attestation if enabled
        if (isUSACSAttestationEnabled) {
            htmlContent += `<br><div>${USACS_ATTESTATION}</div>`;
        }

        if (isPatientConsentEnabled) {
            setPendingCopyContent(htmlContent);
            setIsConsentModalOpen(true);
        } else {
            // If consent workflow is not enabled, proceed with direct copy
            const plainTextContent = convertHtmlToPlainText(htmlContent);

            switch (type) {
                case 'hpi':
                    setCopiedHPI(true);
                    updateDocument(chart.document_id, 'copied_hpi', true);
                    setTimeout(() => setCopiedHPI(false), 3000);
                    break;
                case 'ros':
                    setCopiedROS(true);
                    updateDocument(chart.document_id, 'copied_ros', true);
                    setTimeout(() => setCopiedROS(false), 3000);
                    break;
                case 'physical_exam':
                    setCopiedExam(true);
                    updateDocument(chart.document_id, 'copied_physical_exam', true);
                    setTimeout(() => setCopiedExam(false), 3000);
                    break;
                case 'reevaluation':
                    setCopiedReevaluation(true);
                    updateDocument(chart.document_id, 'copied_reevaluation', true);
                    setTimeout(() => setCopiedReevaluation(false), 3000);
                    break;
                case 'procedures':
                    setCopiedProcedures(true);
                    updateDocument(chart.document_id, 'copied_procedures', true);
                    setTimeout(() => setCopiedProcedures(false), 3000);
                    break;
                case 'mdm':
                    setCopiedMDM(true);
                    updateDocument(chart.document_id, 'copied_mdm', true);
                    setTimeout(() => setCopiedMDM(false), 3000);
                    break;
            }

            if (navigator.clipboard && window.isSecureContext) {
                const htmlType = 'text/html';
                const textType = 'text/plain';

                const htmlBlob = new Blob([htmlContent], { type: htmlType });
                const textBlob = new Blob([plainTextContent], { type: textType });

                const data = new ClipboardItem({
                    [htmlType]: htmlBlob,
                    [textType]: textBlob,
                });

                navigator.clipboard.write([data]).then(
                    () => {
                        console.log('Copied to clipboard successfully!');
                    },
                    (err) => {
                        console.error('Could not copy text: ', err);
                        fallbackCopyToClipboard(plainTextContent);
                    }
                );
            } else {
                fallbackCopyToClipboard(plainTextContent);
            }
        }
    };

    const copyMDMToClipboard = (content) => {
        // Prepare HTML content
        let htmlContent = `<div>${content}</div>`;

        // Add USACS attestation if enabled
        if (isUSACSAttestationEnabled) {
            htmlContent += `<br><div>${USACS_ATTESTATION}</div>`;
        }

        if (isPatientConsentEnabled) {
            // Use the same consent workflow as copyToClipboard
            setPendingCopyContent(htmlContent);
            setIsConsentModalOpen(true);
        } else {
            // If consent workflow is not enabled, proceed with direct copy
            setCopiedMDM(true);
            updateDocument(chart.document_id, 'copied_mdm', true);
            setTimeout(() => setCopiedMDM(false), 3000);

            // Convert HTML content to plain text
            const mdmPlainText = convertHtmlToPlainText(content);

            // Add attestation to plain text if enabled
            const plainTextContent = isUSACSAttestationEnabled
                ? `${mdmPlainText}\n\n${USACS_ATTESTATION}`.replace(/\n/g, '\r\n')
                : mdmPlainText.replace(/\n/g, '\r\n');

            if (navigator.clipboard && window.isSecureContext) {
                // Use the Async Clipboard API for both HTML and plain text content
                const htmlType = 'text/html';
                const textType = 'text/plain';

                const htmlBlob = new Blob([htmlContent], { type: htmlType });
                const textBlob = new Blob([plainTextContent], { type: textType });

                const data = new ClipboardItem({
                    [htmlType]: htmlBlob,
                    [textType]: textBlob,
                });

                navigator.clipboard.write([data]).then(
                    () => {
                        console.log('Copied to clipboard successfully!');
                    },
                    (err) => {
                        console.error('Could not copy text: ', err);
                        // Fallback to legacy method
                        fallbackCopyToClipboard(plainTextContent);
                    }
                );
            } else {
                // Fallback for insecure contexts or unsupported browsers
                fallbackCopyToClipboard(plainTextContent);
            }
        }
    };


    const updateDocument = async (documentId, fieldToUpdate, newValue) => {
        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: documentId,
                        field_to_update: fieldToUpdate,
                        new_value: newValue
                    })
                }
            );
            console.log('Updated', fieldToUpdate, 'to', newValue, 'for document', documentId);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [fetchDocument]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            console.log('handleClickOutside called, target:', event.target);

            // Handle all macro dropdowns
            if (hpiMacroDropdownRef.current && !hpiMacroDropdownRef.current.contains(event.target)) {
                setShowHpiMacroDropdown(false);
            }
            if (rosMacroDropdownRef.current && !rosMacroDropdownRef.current.contains(event.target)) {
                setShowRosMacroDropdown(false);
            }
            if (examMacroDropdownRef.current && !examMacroDropdownRef.current.contains(event.target)) {
                setShowExamMacroDropdown(false);
            }
            if (reevaluationMacroDropdownRef.current && !reevaluationMacroDropdownRef.current.contains(event.target)) {
                setShowReevaluationMacroDropdown(false);
            }
            if (proceduresMacroDropdownRef.current && !proceduresMacroDropdownRef.current.contains(event.target)) {
                setShowProceduresMacroDropdown(false);
            }
            if (mdmMacrosDropdownRef.current && !mdmMacrosDropdownRef.current.contains(event.target)) {
                setShowMDMMacrosDropdown(false);
            }

            // Handle other dropdowns (existing code)
            if (riskScoreDropdownRef.current && !riskScoreDropdownRef.current.contains(event.target)) {
                console.log('Click outside risk score dropdown detected');
                console.log('riskScoreDropdownRef.current:', riskScoreDropdownRef.current);
                setShowRiskScoreDropdown(false);
            } else if (riskScoreDropdownRef.current) {
                console.log('Click inside risk score dropdown or ref not set');
                console.log('riskScoreDropdownRef.current:', riskScoreDropdownRef.current);
            }
            if (macroDropdownRef.current && !macroDropdownRef.current.contains(event.target)) {
                setShowMacroDropdown(false);
            }
        };

        // Attach the listener if any dropdown is visible
        const anyDropdownVisible =
            showHpiMacroDropdown ||
            showRosMacroDropdown ||
            showExamMacroDropdown ||
            showReevaluationMacroDropdown ||
            showProceduresMacroDropdown ||
            showMDMMacrosDropdown ||
            showRiskScoreDropdown ||
            showMacroDropdown;

        if (anyDropdownVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the listener
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [
        showHpiMacroDropdown,
        showRosMacroDropdown,
        showExamMacroDropdown,
        showReevaluationMacroDropdown,
        showProceduresMacroDropdown,
        showMDMMacrosDropdown,
        showRiskScoreDropdown,
        showMacroDropdown
    ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Get the button element and dropdown menu
            const button = document.querySelector('[title="More Actions"]');
            const menu = document.querySelector('[role="menu"]');

            // Only close if the click is outside both the menu and the button
            if (showActionsMenu &&
                !button?.contains(event.target) &&
                !menu?.contains(event.target)) {
                setShowActionsMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showActionsMenu]);


    const goBack = () => {
        navigate('/er-dashboard', { state: { providedDate: providedDate } });
    };

    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };

    useEffect(() => {
        if (saveTriggered) {
            saveDocument('reevaluation');
            setSaveTriggered(false);
        }
    }, [reevaluation, saveTriggered]);

    const getMacrosForSection = (section) => {
        return macro_list.filter((macro) => macro.section === section);
    };


    const toggleBracketedText = () => {
        if (!isRemoved) {
            const bracketedPattern = /^[^\S\r\n]*.*?\[\*\*\*.*?\*\*\*\].*$(\r?\n|\r)?/gm;
            const cleanedMDM = mdm.replace(bracketedPattern, '').trim();
            setOriginalMDM(mdm); // Save the current state before changing
            setCleanedMDM(cleanedMDM);
            setMDM(cleanedMDM);
            setIsRemoved(true); // Update flag to indicate the text is removed
        } else if (mdm === cleanedMDM) {
            setMDM(originalMDM);
            setIsRemoved(false);
        } else {
            alert("MDM has been edited since bracketremoval; undo is not available.");
        }
    };

    const handleCreateMacro = () => {
        navigate('/settings/macros');
    };

    useEffect(() => {
        const handleCopy = (e) => {
            const selection = window.getSelection();
            if (!selection || selection.isCollapsed) return;

            const range = selection.getRangeAt(0);
            const container = document.createElement('div');
            container.appendChild(range.cloneContents());
            const html = container.innerHTML;

            if (html) {
                e.clipboardData.setData('text/html', html);
                e.clipboardData.setData('text/plain', selection.toString());
                e.preventDefault();
            }
        };

        document.addEventListener('copy', handleCopy);

        return () => {
            document.removeEventListener('copy', handleCopy);
        };
    }, [])


    const handleRefresh = () => {
        const iconElement = document.getElementById('refresh-icon');
        if (iconElement) {
            iconElement.classList.add('spin-once');
            setTimeout(() => {
                iconElement.classList.remove('spin-once');
            }, 500); // Duration of the spin animation
        }
        fetchDocument();
    };

    // Update the handleModalVisibility function to use the new panel management
    const handleModalVisibility = (modalToOpen) => {
        switch (modalToOpen) {
            case 'risk':
                expandPanel('riskMessages');
                break;
            case 'mdm':
                expandPanel('mdmCoding');
                break;
            default:
                collapsePanel();
                break;
        }
    };

    return (
        <div className="flex flex-1 overflow-hidden min-h-0 pl-6 pr-6 pb-6">

            <div className="flex flex-1">
                {/* Main Content */}
                <div className="flex-1 flex flex-col min-h-0">
                    {/* Document Details */}
                    {chart && (
                        <div className="pt-4 pr-4 pl-4 pb-2 border-b border-gray-300 flex items-center justify-between">
                            {/* Left Side: Go Back Button, Patient Name, Procedure Description, and Encounter Date */}
                            <div className="flex items-center space-x-4">
                                {/* Go Back Button */}
                                <button
                                    onClick={goBack}
                                    className="flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-1" />
                                    Back to Dashboard
                                </button>

                                {/* Patient Name */}
                                <div
                                    className="cursor-pointer"
                                    onDoubleClick={() => setIsEditingPatientName(true)}
                                >
                                    {isEditingPatientName ? (
                                        <input
                                            type="text"
                                            className="w-48 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            value={editedDocument.patient_name}
                                            onChange={(e) => updateSection('patient_name', e.target.value)}
                                            onBlur={() => {
                                                setIsEditingPatientName(false);
                                                saveDocument('patient_name');
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    saveDocument('patient_name');
                                                    setIsEditingPatientName(false);
                                                }
                                            }}
                                            autoFocus
                                            aria-label="Edit Patient Name"
                                        />
                                    ) : (
                                        <p className="text-2xl font-semibold text-gray-800">{patientName}</p>
                                    )}
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Procedure Description */}
                                <div
                                    className="cursor-pointer"
                                    onDoubleClick={() => setIsEditingProcedureDescription(true)}
                                >
                                    {isEditingProcedureDescription ? (
                                        <input
                                            type="text"
                                            className="w-64 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 text-md"
                                            value={editedDocument.procedure_description}
                                            onChange={(e) => updateSection('procedure_description', e.target.value)}
                                            onBlur={() => {
                                                setIsEditingProcedureDescription(false);
                                                saveDocument('procedure_description');
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    saveDocument('procedure_description');
                                                    setIsEditingProcedureDescription(false);
                                                }
                                            }}
                                            autoFocus
                                            aria-label="Edit Procedure Description"
                                        />
                                    ) : (
                                        <p className="text-lg text-gray-700">{procedureDescription}</p>
                                    )}
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Encounter Date */}
                                <div className="text-gray-600">
                                    <p className="text-md">
                                        {formatDate(chart.created_at)} at {formatTime(chart.created_at)}
                                    </p>
                                </div>
                            </div>

                            {/* Right Side: Action Buttons */}
                            <div className="flex items-center space-x-2">
                                {generateTreatmentPlanEnabled && (
                                    <button
                                        onClick={() => setIsTreatmentPlanModalOpen(true)}
                                        className="flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    >
                                        <FontAwesomeIcon icon={faNotesMedical} className="mr-1" />
                                        Generate Treatment Plan
                                    </button>
                                )}

                                {/* Copy All Button */}
                                {!(documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' || documentStatus === 'IN_PROGRESS') && (
                                    <button
                                        onClick={isCustomMDMExempt ? copyAllToClipboardPlain : copyAllToClipboardHtml}
                                        className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                        title={copiedAll ? "Copied!" : "Copy All"}
                                    >
                                        <FontAwesomeIcon icon={copiedAll ? faCheck : faCopy} className="h-5 w-5" />
                                        <span>{copiedAll ? "Copied!" : "Copy All"}</span>
                                    </button>
                                )}

                                {/* Refresh Button */}
                                <button
                                    onClick={fetchDocument}
                                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                    title="Refresh"
                                >
                                    <FontAwesomeIcon icon={faSync} className="h-5 w-5" />
                                    <span>Refresh</span>
                                </button>

                                {/* Risk Score Button */}
                                {!isSettingsLoading && getSetting('hide_risk_score_button') !== true && (
                                    <RiskScoreButton
                                        riskScores={riskScores}
                                        documentStatus={documentStatus}
                                        reevaluation={reevaluation}
                                        updateSection={updateSection}
                                        setSaveTriggered={setSaveTriggered}
                                        currentRiskScoreId={currentRiskScoreId}
                                        setCurrentRiskScoreId={setCurrentRiskScoreId}
                                        riskScoreCalculatorVisible={riskScoreCalculatorVisible}
                                        setRiskScoreCalculatorVisible={setRiskScoreCalculatorVisible}
                                        dropdownRef={riskScoreDropdownRef}
                                        showRiskScoreDropdown={showRiskScoreDropdown}
                                        setShowRiskScoreDropdown={setShowRiskScoreDropdown}
                                    />
                                )}


                                {/* Supplemental Documents Button */}
                                {!isSettingsLoading && getSetting('hide_supplemental_documents') !== true && (
                                    <button
                                        onClick={() => setIsSupplementalDocsModalOpen(true)}
                                        className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200"
                                        title="Supplemental Documents"
                                    >
                                        <DocumentDuplicateIcon className="h-5 w-5" />
                                        <span>Supplemental Docs</span>
                                    </button>
                                )}


                                {/* Replace the Archive and Delete buttons with this Menu button */}
                                <div className="relative">
                                    <button
                                        onClick={() => setShowActionsMenu(!showActionsMenu)}
                                        className="flex items-center bg-indigo-900 text-white rounded-lg px-2 py-2 hover:bg-indigo-800 transition-shadow duration-200 h-[40px]" // Adjusted padding and added fixed height
                                        title="More Actions"
                                    >
                                        <FontAwesomeIcon
                                            icon={faEllipsisVertical}
                                            className="h-5 w-5"
                                        />
                                    </button>

                                    {/* Dropdown Menu */}
                                    {showActionsMenu && (
                                        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                                            <div className="py-1" role="menu">
                                                <button
                                                    onClick={() => {
                                                        toggleArchiveStatus();
                                                        setShowActionsMenu(false);
                                                    }}
                                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                                                    role="menuitem"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={documentStatus === "FINALIZED" ? faUndo : faArchive}
                                                        className="h-4 w-4"
                                                    />
                                                    <span>{documentStatus === "FINALIZED" ? "Unarchive" : "Archive"}</span>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsDeleteDialogOpen(true);
                                                        setShowActionsMenu(false);
                                                    }}
                                                    className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100 flex items-center space-x-2"
                                                    role="menuitem"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className="h-4 w-4" />
                                                    <span>Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* Delete Confirmation Dialog */}
                                <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                                    <DialogTitle>
                                        <div className="flex items-center space-x-2 text-red-600">
                                            <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6" />
                                            <span className="text-xl font-bold">Delete Document</span>
                                        </div>
                                    </DialogTitle>
                                    <DialogBody>
                                        <DialogDescription>
                                            <div className="space-y-4">
                                                <div className="text-lg font-semibold text-gray-900">
                                                    Are you sure you want to permanently delete this document?
                                                </div>
                                                <div className="text-sm text-gray-600">
                                                    This action cannot be undone.
                                                </div>
                                            </div>
                                        </DialogDescription>
                                    </DialogBody>
                                    <DialogActions>
                                        <Button
                                            onClick={() => setIsDeleteDialogOpen(false)}
                                            disabled={isDeleting}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={handleDeleteConfirm}
                                            className="bg-red-600 hover:bg-red-700 disabled:opacity-50"
                                            disabled={isDeleting}
                                        >
                                            {isDeleting ? (
                                                <div className="flex items-center space-x-2">
                                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    <span>Deleting...</span>
                                                </div>
                                            ) : (
                                                'Delete Document'
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                    )}



                    {/* Check Document Status */}
                    {documentStatus === 'PRE_ENCOUNTER' || documentStatus === 'ENCOUNTER_STARTED' ? (
                        <div className="flex-1 flex items-center justify-center">
                            <p className="text-xl text-gray-600">Begin your encounter from your mobile app.</p>
                        </div>
                    ) : documentStatus === 'IN_PROGRESS' ? (
                        // Case: IN_PROGRESS but all sections are empty (Whole note is in progress)
                        <div className="flex-1 flex flex-col items-center justify-center space-y-4">
                            <p className="text-xl text-gray-600 text-center">
                                This chart is currently in progress and not available yet.
                            </p>
                            <button
                                onClick={handleRefresh}
                                className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 shadow-lg hover:shadow-xl"
                                title="Refresh"
                            >
                                <FontAwesomeIcon id="refresh-icon" icon={faSync} className="h-5 w-5" />
                                <span>Refresh</span>
                            </button>
                        </div>
                    ) : (
                        // Default case: Display the note sections
                        <>

                            {/* Clinical Note Section */}
                            {isLoading ? (
                                <div className="loading-overlay">
                                    <div className="spinner"></div>
                                    <div className="loading-text">{loadingMessage}</div>
                                </div>
                            ) : (
                                <div className="flex-1 overflow-y-auto pr-72">
                                    <div className="space-y-2 p-5">
                                        {/* History of Present Illness Section */}
                                        <NoteSectionEditor
                                            sectionName="hpi"
                                            displayTitle="History of Present Illness"
                                            content={hpi}
                                            copied={copiedHPI}
                                            isLoading={sectionLoadingStates.hpi}
                                            showMacroDropdown={showHpiMacroDropdown}
                                            setShowMacroDropdown={setShowHpiMacroDropdown}
                                            updateSection={updateSection}
                                            saveDocument={saveDocument}
                                            copyToClipboard={copyToClipboard}
                                            getMacrosForSection={getMacrosForSection}
                                            insertSelectedMacro={insertSelectedMacro}
                                            handleCreateMacro={handleCreateMacro}
                                            recordingManager={recordingManager}
                                            chart={chart}
                                            isRecording={isRecording}
                                            setIsRecording={setIsRecording}
                                            currentRecordingSection={currentRecordingSection}
                                            setCurrentRecordingSection={setCurrentRecordingSection}
                                            sectionLoadingStates={sectionLoadingStates}
                                            setSectionLoadingStates={setSectionLoadingStates}
                                            showBorder={false}
                                            dropdownRef={hpiMacroDropdownRef}
                                        />

                                        {/* Review of Systems Section */}
                                        {!actorInfo.transfer_ros_to_hpi && (
                                            <NoteSectionEditor
                                                sectionName="ros"
                                                displayTitle="Review of Systems"
                                                content={ros}
                                                copied={copiedROS}
                                                isLoading={sectionLoadingStates.ros}
                                                showMacroDropdown={showRosMacroDropdown}
                                                setShowMacroDropdown={setShowRosMacroDropdown}
                                                updateSection={updateSection}
                                                saveDocument={saveDocument}
                                                copyToClipboard={copyToClipboard}
                                                getMacrosForSection={getMacrosForSection}
                                                insertSelectedMacro={insertSelectedMacro}
                                                handleCreateMacro={handleCreateMacro}
                                                recordingManager={recordingManager}
                                                chart={chart}
                                                isRecording={isRecording}
                                                setIsRecording={setIsRecording}
                                                currentRecordingSection={currentRecordingSection}
                                                setCurrentRecordingSection={setCurrentRecordingSection}
                                                sectionLoadingStates={sectionLoadingStates}
                                                setSectionLoadingStates={setSectionLoadingStates}
                                                dropdownRef={rosMacroDropdownRef}
                                            />
                                        )}

                                        {/* Physical Exam Section */}
                                        <NoteSectionEditor
                                            sectionName="physical_exam"
                                            displayTitle="Physical Exam"
                                            content={exam}
                                            copied={copiedExam}
                                            isLoading={sectionLoadingStates.physical_exam}
                                            showMacroDropdown={showExamMacroDropdown}
                                            setShowMacroDropdown={setShowExamMacroDropdown}
                                            dropdownRef={examMacroDropdownRef}
                                            getMacrosForSection={getMacrosForSection}
                                            insertSelectedMacro={insertSelectedMacro}
                                            handleCreateMacro={handleCreateMacro}
                                            saveDocument={saveDocument}
                                            copyToClipboard={copyToClipboard}
                                            updateSection={updateSection}
                                            onBlur={() => saveDocument('physical_exam', exam)}
                                            recordingManager={recordingManager}
                                            chart={chart}
                                            isRecording={isRecording}
                                            setIsRecording={setIsRecording}
                                            currentRecordingSection={currentRecordingSection}
                                            setCurrentRecordingSection={setCurrentRecordingSection}
                                            sectionLoadingStates={sectionLoadingStates}
                                            setSectionLoadingStates={setSectionLoadingStates}
                                            useCodeMirror={false}
                                            isMIPSModeEnabled={false}
                                        />

                                        {/* Evaluations Section */}
                                        {!actorInfo.hide_reevaluation_section && (
                                            <NoteSectionEditor
                                                sectionName="reevaluation"
                                                displayTitle="Evaluations"
                                                content={reevaluation}
                                                copied={copiedReevaluation}
                                                isLoading={sectionLoadingStates.reevaluation}
                                                showMacroDropdown={showReevaluationMacroDropdown}
                                                setShowMacroDropdown={setShowReevaluationMacroDropdown}
                                                dropdownRef={reevaluationMacroDropdownRef}
                                                getMacrosForSection={getMacrosForSection}
                                                insertSelectedMacro={insertSelectedMacro}
                                                handleCreateMacro={handleCreateMacro}
                                                saveDocument={saveDocument}
                                                copyToClipboard={copyToClipboard}
                                                updateSection={updateSection}
                                                onBlur={() => saveDocument('reevaluation', reevaluation)}
                                                recordingManager={recordingManager}
                                                chart={chart}
                                                isRecording={isRecording}
                                                setIsRecording={setIsRecording}
                                                currentRecordingSection={currentRecordingSection}
                                                setCurrentRecordingSection={setCurrentRecordingSection}
                                                sectionLoadingStates={sectionLoadingStates}
                                                setSectionLoadingStates={setSectionLoadingStates}
                                                useCodeMirror={isMIPSModeEnabled}
                                                isMIPSModeEnabled={isMIPSModeEnabled}
                                            />
                                        )}

                                        {/* Procedures Section */}
                                        {isProceduresEnabled && (
                                            <NoteSectionEditor
                                                sectionName="procedures"
                                                displayTitle="Procedures & Attestations"
                                                subtitle="Will appear below MDM upon copy all"
                                                content={procedures}
                                                placeholder="No Procedures Found."
                                                copied={copiedProcedures}
                                                isLoading={sectionLoadingStates.procedures}
                                                showMacroDropdown={showProceduresMacroDropdown}
                                                setShowMacroDropdown={setShowProceduresMacroDropdown}
                                                dropdownRef={proceduresMacroDropdownRef}
                                                getMacrosForSection={getMacrosForSection}
                                                insertSelectedMacro={insertSelectedMacro}
                                                handleCreateMacro={handleCreateMacro}
                                                saveDocument={saveDocument}
                                                copyToClipboard={copyToClipboard}
                                                updateSection={updateSection}
                                                onBlur={() => saveDocument('procedures', procedures)}
                                                recordingManager={recordingManager}
                                                chart={chart}
                                                isRecording={isRecording}
                                                setIsRecording={setIsRecording}
                                                currentRecordingSection={currentRecordingSection}
                                                setCurrentRecordingSection={setCurrentRecordingSection}
                                                sectionLoadingStates={sectionLoadingStates}
                                                setSectionLoadingStates={setSectionLoadingStates}
                                                useCodeMirror={isMIPSModeEnabled}
                                                isMIPSModeEnabled={isMIPSModeEnabled}
                                            />
                                        )}


                                        {/* Medical Decision-Making Documentation (MDM) Section */}
                                        <div className="flex flex-col border-t border-gray-300 mt-2">
                                            <div className="flex items-center mt-2 justify-start space-x-2">
                                                <div className="font-bold text-slate-700 text-md">Medical Decision-Making</div>
                                                <div className="flex items-center space-x-2 pl-4">
                                                    {mdmStatus !== "IN_PROGRESS" && (
                                                        <button
                                                            onClick={generateMDM}
                                                            className={`flex items-center space-x-2 rounded-lg px-3 py-2 cursor-pointer ${mdm === null || mdm === ''
                                                                ? "bg-indigo-900 hover:bg-indigo-800 text-white"
                                                                : "border border-[#DB104E] bg-transparent text-[#DB104E] hover:bg-[#DB104E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#DB104E] transition duration-150 ease-in-out"
                                                                }`}
                                                            title={mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}
                                                        >
                                                            <FontAwesomeIcon icon={mdm === null || mdm === '' ? faCalculator : faSync} className="h-5 w-5" />
                                                            <span>{mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}</span>
                                                        </button>
                                                    )}
                                                    {mdmStatus === "IN_PROGRESS" && (
                                                        <div className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2">
                                                            <FontAwesomeIcon icon={faSpinner} spin className="h-5 w-5" />
                                                            <span>Generating MDM...</span>
                                                        </div>
                                                    )}

                                                    {mdm && (
                                                        <>

                                                            {isCustomMDMExempt && (
                                                                <button
                                                                    className={`rounded-md text-indigo-900 px-3 text-sm hover:bg-gray-200 focus:outline-none ${isRemoved && mdm !== cleanedMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                                                    onClick={toggleBracketedText}
                                                                    disabled={isRemoved && mdm !== cleanedMDM}
                                                                >
                                                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                                                </button>
                                                            )}
                                                            <button
                                                                className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                onClick={() => saveDocument('mdm')}
                                                            >
                                                                Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                                            </button>
                                                            {copiedMDM ? (
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() => {
                                                                        if (!isCustomMDMExempt) {
                                                                            copyMDMToClipboard(mdm, 'mdm');
                                                                        } else {
                                                                            copyToClipboard(mdm, 'mdm');
                                                                        }
                                                                    }}
                                                                >
                                                                    Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() => {
                                                                        if (!isCustomMDMExempt) {
                                                                            copyMDMToClipboard(mdm, 'mdm');
                                                                        } else {
                                                                            copyToClipboard(mdm, 'mdm');
                                                                        }
                                                                    }}
                                                                >
                                                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                                </button>
                                                            )}

                                                            {/* Macro Dropdown Button */}
                                                            <div className="relative" ref={mdmMacrosDropdownRef}>
                                                                <button
                                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                                    onClick={() =>
                                                                        setShowMDMMacrosDropdown(!showMDMMacrosDropdown)
                                                                    }
                                                                >
                                                                    Macros{' '}
                                                                    <FontAwesomeIcon
                                                                        icon={showMDMMacrosDropdown ? faChevronUp : faChevronDown}
                                                                        className="ml-2"
                                                                    />
                                                                </button>


                                                                {/* Macro Dropdown */}
                                                                {showMDMMacrosDropdown && (
                                                                    <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                                        <div className="py-1">
                                                                            {getMacrosForSection('mdm').map(
                                                                                (macro) => (
                                                                                    <button
                                                                                        key={macro.macro_id}
                                                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                                        onClick={() => {
                                                                                            insertSelectedMacro(
                                                                                                macro,
                                                                                                'mdm'
                                                                                            );
                                                                                            setShowMDMMacrosDropdown(false);
                                                                                        }}
                                                                                    >
                                                                                        {macro.title}
                                                                                    </button>
                                                                                )
                                                                            )}
                                                                            {getMacrosForSection('mdm').length ===
                                                                                0 && (
                                                                                    <div className="text-center">
                                                                                        <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                                        <div className="mt-6">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                                onClick={handleCreateMacro}
                                                                                            >
                                                                                                + New Macro
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <SmartEditButton
                                                                section="mdm"
                                                                label="MDM"
                                                                recordingManager={recordingManager}
                                                                chart={chart}
                                                                updateSection={updateSection}
                                                                isRecording={isRecording}
                                                                setIsRecording={setIsRecording}
                                                                currentRecordingSection={currentRecordingSection}
                                                                setCurrentRecordingSection={setCurrentRecordingSection}
                                                                sectionLoadingStates={sectionLoadingStates}
                                                                setSectionLoadingStates={setSectionLoadingStates}
                                                            />
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                            {sectionLoadingStates.mdm ? (
                                                <div className="flex justify-center items-center h-full">
                                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                                </div>
                                            ) : mdmStatus === "IN_PROGRESS" ? (
                                                <div className="w-full rounded p-2 text-slate-400 italic">
                                                    Generating MDM...
                                                </div>
                                            ) : mdm ? (
                                                !isCustomMDMExempt ? (
                                                    <TipTapEditor mdm={mdm} updateSection={updateSection} saveDocument={saveDocument} />
                                                ) : (
                                                    <TextareaAutosize
                                                        className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                                        value={mdm || ''}
                                                        onChange={(e) => updateSection('mdm', e.target.value)}
                                                        onBlur={() => saveDocument('mdm', mdm)}
                                                        rows={10}
                                                    />
                                                )
                                            ) : (
                                                <div className="w-full rounded p-2 text-slate-400 italic">
                                                    MDM not yet generated. Click 'Generate MDM' to create.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* NOTIFICATION SECTIONS */}


                {/* Notification Card for Risk Score */}
                {showNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-green-800">Successfully saved</p>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                            onClick={() => setShowNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showErrorNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">Error</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <p>{errorMessage}</p>
                                    </div>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                            onClick={() => setShowErrorNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <RiskMessagesButton
                    riskMessages={riskMessages}
                    hasRiskMessages={hasRiskMessages}
                    isShowingRiskMessages={isPanelExpanded('riskMessages')}
                    setIsShowingRiskMessages={(isShowing) => isShowing ? expandPanel('riskMessages') : collapsePanel()}
                    setCurrentRiskScoreId={setCurrentRiskScoreId}
                    setRiskScoreCalculatorVisible={setRiskScoreCalculatorVisible}
                    documentID={chart.document_id}
                    updateSection={updateSection}
                    setSaveTriggered={setSaveTriggered}
                />


                {/* MDM Coding Button */}
                {!isSettingsLoading && getSetting('hide_mdm_assistant') !== true && (
                    <MDMCodingButton
                        mdmStatus={mdmStatus}
                        mdmCodingNotificationVisible={isPanelExpanded('mdmCoding')}
                        setMDMCodingNotificationVisible={(isVisible) => isVisible ? expandPanel('mdmCoding') : collapsePanel()}
                        copa={copa}
                        complexityOfData={complexityOfData}
                        riskOfComplications={riskOfComplications}
                        handleModalVisibility={handleModalVisibility}
                    />
                )}

                {/* CPT Codes Button */}
                {isCPTCodesEnabled && (
                    <CPTCodesButton
                        isCPTCodesVisible={isPanelExpanded('cptCodes')}
                        setIsCPTCodesVisible={(isVisible) => isVisible ? expandPanel('cptCodes') : collapsePanel()}
                        parsedCPTProceduralCodes={parsedCPTProceduralCodes}
                        setParsedCPTProceduralCodes={setParsedCPTProceduralCodes}
                        parsedCPTEMCodes={parsedCPTEMCodes}
                        setParsedCPTEMCodes={setParsedCPTEMCodes}
                        documentID={chart.document_id}
                        updateDocument={updateDocument}
                    />
                )}

                {(isPatientExperienceEnabled || isPatientExperienceEnabledWebApp || (!isSettingsLoading && getSetting('patient_experience_feedback') == true)) && (
                    <PatientExperienceButton
                        onClick={() => setIsPatientExperienceModalOpen(true)}
                        hasNullCriteria={hasNullCriteria}
                        completedCriteria={completedCriteria}
                        totalCriteria={totalCriteria}
                        completionPercentage={completionPercentage}
                    />
                )}

                {/* Note Feedback Component */}
                <NoteFeedbackButton
                    isFeedbackVisible={isFeedbackVisible}
                    setIsFeedbackVisible={setIsFeedbackVisible}
                    documentID={chart.document_id}
                />

                {showForceResolveModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto">
                            <h2 className="text-xl font-semibold mb-4 text-red-600">
                                Required Risk Items Unresolved
                            </h2>
                            <p className="mb-6">
                                Ensure all required risk items are resolved before completing your chart.
                            </p>
                            <button
                                onClick={() => setShowForceResolveModal(false)}
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-indigo-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}

                {isPatientExperienceModalOpen && (
                    <PatientExperienceModal
                        isOpen={isPatientExperienceModalOpen}
                        onClose={() => setIsPatientExperienceModalOpen(false)}
                        criteriaData={{
                            acknowledgedPatient,
                            introducedThemselves,
                            providedDuration,
                            providedExplanation,
                            managedUpCareTeam
                        }}
                    />
                )}

                <SupplementalDocumentsModal
                    isOpen={isSupplementalDocsModalOpen}
                    onClose={() => setIsSupplementalDocsModalOpen(false)}
                    documentId={chart.document_id}
                    onSuccess={(message) => {
                        // Add any additional logic you want to execute when documents are successfully uploaded
                        console.log('Documents uploaded successfully:', message);
                        // Optionally, you can set a success message in the UI
                    }}
                />
            </div>
            <ConsentModal
                isOpen={isConsentModalOpen}
                onClose={() => setIsConsentModalOpen(false)}
                onSelect={handleConsentSelect}
            />
            {/* Add the Treatment Plan Modal */}
            <TreatmentPlanModal
                isOpen={isTreatmentPlanModalOpen}
                onClose={() => setIsTreatmentPlanModalOpen(false)}
                documentId={chart?.document_id}
            />
        </div>
    );
}

export default PatientInfoCarepoint;