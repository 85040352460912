// src/pages/Home.js

import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Fieldset, Legend, FieldGroup } from "../components/catalyst/fieldset";
import { protectedResources } from "../authConfig";
import { usePostHog } from "posthog-js/react";
import * as Sentry from "@sentry/browser";
import { Fab } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { ContactUsForm } from '../components/ContactUsForm';

export const Home = () => {
  const posthog = usePostHog();
  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();
  const navigate = useNavigate();
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [previousAuthState, setPreviousAuthState] = useState(isAuthenticated);
  const [contactFormOpen, setContactFormOpen] = useState(false);

  // Add logging for auth state changes
  useEffect(() => {
  }, [isAuthenticated, previousAuthState, isAuth0Loading, user, fetchAttempted]);

  // Add logging for user changes
  useEffect(() => {
  }, [user]);

  const loadingMessage =
    isAuth0Loading && !previousAuthState ? "Securely Logging In..." :
      isAuth0Loading && previousAuthState ? "Securely Logging Out..." :
        isLoading ? "Loading..." : "";

  // Track authentication state changes
  useEffect(() => {
    setPreviousAuthState(isAuthenticated);
  }, [isAuthenticated]);

  const fetchUserInfo = useCallback(async () => {
    if (isAuthenticated && user) {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const fetchResponse = await fetch(
          protectedResources.apiGetMyInfo.endpoint,
          requestOptions
        );
        const actorInfo = await fetchResponse.json();
        const actorSpecialty = actorInfo.actor_specialty;
        const actorStatus = actorInfo.actor_status;

        posthog?.identify(actorInfo.actor_uuid, {
          email: user.email,
          name: user.name,
          org_id: actorInfo.org_id
        });

        // Add Sentry identification
        Sentry.setUser({
          id: actorInfo.actor_uuid,
          email: actorInfo.email
        });

        window.Appcues.identify(actorInfo.actor_uuid, {
          email: actorInfo.email,
          name: actorInfo.full_name,
          org_id: actorInfo.org_id,
          sign_up_wave: actorInfo.sign_up_wave,
          subscription_type: actorInfo.subscription_type,
          actor_specialty: actorInfo.actor_specialty,
        });

        window.Canny('identify', {
          appID: '669537b36dd307ebca975ed7',
          user: {
            email: actorInfo.email,
            name: actorInfo.full_name,
            id: actorInfo.actor_uuid,
          },
        });

        // New condition to check actorStatus
        if (actorStatus === "INACTIVE") {
          console.log("Actor is inactive");
          navigate("/subscribe", { state: { actorInfo: actorInfo } });
        } else if (!actorInfo.accepted_most_recent_terms || !actorInfo.accepted_most_recent_privacy_policy) {
          navigate("/accept-policies", { state: { actorInfo: actorInfo } });
        } else if (actorInfo.org_id === 5) {
          if (actorSpecialty === "HOSPITALIST") {
            navigate("/hospitalist-inpatient-dashboard", { state: { actorInfo: actorInfo } });
          } else if (posthog.isFeatureEnabled('ask_carepoint_location')) {
            const loggedRecently = await checkLoginLocationRecently(token);
            if (!loggedRecently) {
              console.log("Actor has not logged a location in the last 24 hours");
              navigate("/carepoint-location-survey", { state: { actorInfo: actorInfo } });
            } else {
              console.log("Actor has logged a location in the last 24 hours");
              navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
            }
          } else {
            navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
          }

        } else if (
          actorSpecialty === "EMERGENCY_PHYSICIAN" ||
          actorSpecialty === "ADVANCED_PRACTICE_PROVIDER" ||
          actorSpecialty === "EM_RESIDENT"
        ) {
          navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
        } else if (
          actorSpecialty === "HOSPITALIST"
        ) {
          navigate("/hospitalist-inpatient-dashboard", { state: { actorInfo: actorInfo } });
        } else {
          console.error("User does not have proper login credentials.");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isAuthenticated, user, getAccessTokenSilently, navigate, posthog]);

  useEffect(() => {
    if (user && !fetchAttempted) {
      fetchUserInfo();
      setFetchAttempted(true);
    }
  }, [user, fetchAttempted, fetchUserInfo]);

  const checkLoginLocationRecently = async (token) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),  // Empty object as the request payload
    };

    try {
      const response = await fetch(
        protectedResources.apiHasLoggedLocationRecently.endpoint,
        requestOptions
      );
      const data = await response.json();
      return data.answer;  // Assuming the response model has 'answer' field
    } catch (error) {
      console.error("Error checking login location:", error);
      return false;
    }
  };

  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        prompt: 'select_account'
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div className="loadingOverlay">
          <div className="spinner"></div>
          <div className="loadingMessage">{loadingMessage}</div>
        </div>
      )}
      {!isAuthenticated && !isLoading && (
        <div className="flex flex-col items-center justify-center min-h-screen">
          <div className="max-w-lg w-full bg-white shadow-lg rounded-lg pl-12 pr-12 pt-8">
            <div className="mb-12">
              <img
                src="/cleo-logo.png"
                alt="Cleo Logo"
                style={{ maxWidth: "60px", marginBottom: "20px" }}
              />
              <Fieldset>
                <Legend
                  className="text-black pb-2"
                  style={{ fontSize: "24px" }}
                >
                  Sign in to your account
                </Legend>
                <FieldGroup className="flex flex-col items-center">
                  <button
                    onClick={handleLogin}
                    className="self-center mt-8 w-3/4 bg-indigo-900 hover:bg-indigo-900 text-white rounded-md py-1.5 px-4"
                  >
                    Sign In
                  </button>
                </FieldGroup>
              </Fieldset>
            </div>
            <p className="text-center text-black mb-4">
              Click here to view our
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Terms of Use{" "}
              </a>
              and
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      )}
      
      {/* Contact Us button */}
      <Fab
        aria-label="contact us"
        color="primary"
        variant="extended"
        sx={{
          position: 'fixed',
          bottom: '2rem',
          left: '2rem',
          px: 3,
          '& .MuiSvgIcon-root': {
            marginRight: 1
          }
        }}
        onClick={() => setContactFormOpen(true)}
      >
        <ContactSupportIcon />
        Contact Us
      </Fab>
      
      <ContactUsForm
        open={contactFormOpen}
        onClose={() => setContactFormOpen(false)}
      />
    </>
  );
};

export default Home;