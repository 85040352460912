import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../../contexts/AuthProvider';
import { Dialog, Switch } from '@headlessui/react';
import {
    PlusIcon, TrashIcon, PencilIcon,
    ArrowLeftIcon, XMarkIcon
} from '@heroicons/react/20/solid';
import {
    UserCircleIcon, DocumentTextIcon
} from '@heroicons/react/24/outline';
import { useFeatureFlagEnabled } from 'posthog-js/react';

// Import your custom instructions service
import customInstructionsService from '../../services/customInstructionsService';

export const GroupCustomInstructions = ({ groupId, onSuccessfulSave, isModal }) => {
    // State variables
    const { actorInfo } = useAuth();
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();
    const [instructionId, setInstructionId] = useState(null);
    const [instructionName, setInstructionName] = useState('');
    const [instructionContent, setInstructionContent] = useState('');
    const [instructionSection, setInstructionSection] = useState('HPI');
    const [isLoading, setIsLoading] = useState(false);
    const [instructionsList, setInstructionsList] = useState([]);
    const [isEditable, setIsEditable] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedInstructionId, setSelectedInstructionId] = useState(null);
    const [isInherited, setIsInherited] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showSaveConfirmationDialog, setShowSaveConfirmationDialog] = useState(false);

    // Check if user has admin privileges
    const hasAdminPrivileges = actorInfo.admin_status === 'GLOBAL_ADMIN' || actorInfo.admin_status === 'ORGANIZATION_ADMIN';

    // Fetch instructions
    const getInstructions = useCallback(async () => {
        setIsLoading(true);
        try {
            // Pass groupId if user has admin privileges
            const targetGroupId = hasAdminPrivileges ? groupId : null;
            const response = await customInstructionsService.getAdminGroupInstructions(getAccessTokenSilently, targetGroupId);
            console.log(response);

            // Check if response has instructions property and convert to array if needed
            let instructionsArray = [];
            if (response) {
                if (Array.isArray(response)) {
                    instructionsArray = response;
                } else if (response.instructions && typeof response.instructions === 'object') {
                    // Convert object of instructions to array
                    instructionsArray = Object.values(response.instructions);
                }

                setInstructionsList(instructionsArray);

                // If there's a selected instruction, update its details
                if (selectedInstructionId) {
                    const selectedInstruction = instructionsArray.find(i => i.instruction_id === selectedInstructionId);
                    if (selectedInstruction) {
                        updateFormWithInstruction(selectedInstruction);
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching instructions:', error);
        }
        setIsLoading(false);
    }, [getAccessTokenSilently, groupId, hasAdminPrivileges]);

    // Update form with instruction details
    const updateFormWithInstruction = (instruction) => {
        setInstructionId(instruction.instruction_id);
        setInstructionName(instruction.name);
        setInstructionContent(instruction.content);
        setInstructionSection(instruction.section);
        setIsInherited(instruction.is_inherited || false);
        setIsEditable(!instruction.is_inherited);
    };

    // Handle instruction click
    const handleInstructionClick = (instruction) => {
        setSelectedInstructionId(instruction.instruction_id);
        updateFormWithInstruction(instruction);
    };

    // Handle new instruction
    const handleNewInstruction = () => {
        setInstructionId(null);
        setInstructionName('');
        setInstructionContent('');
        setInstructionSection('HPI');
        setSelectedInstructionId(null);
        setIsEditable(true);
        setIsInherited(false);
    };

    // Check if fields are filled
    const areFieldsFilled = () => {
        return instructionName.trim() !== '' && instructionContent.trim() !== '';
    };

    // Handle save click
    const handleSaveClick = async () => {
        if (!areFieldsFilled()) {
            alert("Please fill in all required fields.");
            return;
        }

        // Show confirmation dialog instead of immediately saving
        setShowSaveConfirmationDialog(true);
    };

    // Add this new function after handleSaveClick
    const confirmSave = async () => {
        setShowSaveConfirmationDialog(false);
        await upsertInstruction();
    };

    // Upsert instruction
    const upsertInstruction = async () => {
        setIsLoading(true);
        try {
            const instructionData = {
                instruction_id: instructionId || null,
                name: instructionName.trim(),
                content: instructionContent.trim(),
                section: instructionSection,
                is_admin_instruction: true
            };

            // Pass groupId if user has admin privileges
            const targetGroupId = hasAdminPrivileges ? groupId : null;
            const data = await customInstructionsService.upsertGroupInstruction(
                getAccessTokenSilently,
                instructionData,
                targetGroupId
            );

            if (data.success) {
                await getInstructions();
                setSelectedInstructionId(data.instruction_id);
                onSuccessfulSave(`Instruction "${instructionName}" saved successfully`);
            } else {
                console.error('Error upserting instruction:', data.error);
            }
        } catch (error) {
            console.error('Error updating instruction:', error);
        }
        setIsLoading(false);
    };

    // Handle delete click
    const handleDeleteClick = () => {
        setShowConfirmationDialog(true);
    };

    // Add this function to handle confirmation
    const confirmDelete = () => {
        setShowConfirmationDialog(false);
        handleDelete();
    };

    // Delete instruction
    const handleDelete = async () => {
        setIsLoading(true);
        try {
            const data = await customInstructionsService.deleteGroupInstruction(
                getAccessTokenSilently,
                instructionId,
                groupId
            );

            if (data.success) {
                handleNewInstruction();
                await getInstructions();
                onSuccessfulSave(`Instruction deleted successfully`);
            } else {
                console.error('Error deleting instruction:', data.error);
            }
        } catch (error) {
            console.error('Error deleting instruction:', error);
        }
        setIsLoading(false);
    };

    // Load instructions on component mount
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getInstructions();
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [getInstructions]);

    // Get filtered instruction lists
    const getInstructionLists = useCallback(() => {
        // Ensure instructionsList is an array
        const instructionsArray = Array.isArray(instructionsList) ? instructionsList : [];

        // Filter instructions by inheritance
        const groupInstructions = instructionsArray.filter(instruction => !instruction.is_inherited);
        const inheritedInstructions = instructionsArray.filter(instruction => instruction.is_inherited);

        return {
            groupInstructions,
            inheritedInstructions
        };
    }, [instructionsList]);

    return (
        <>
            <div className={isModal ? '' : 'container'}>
                <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">
                    Group Custom Instructions
                </h1>
                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="flex">
                            {/* Left Column - Instructions List */}
                            <div className="w-1/3 pr-4">
                                <div className="flex flex-col space-y-4">
                                    <button
                                        className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleNewInstruction}>
                                        + New Instruction
                                    </button>

                                    <input
                                        type="text"
                                        placeholder="Search instructions"
                                        className="p-2 border border-gray-300 rounded w-full"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>

                                {/* Group's Own Instructions */}
                                <div className="mt-4">
                                    <h2 className="text-lg font-semibold mb-2">Group Instructions</h2>
                                    <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                                        {isLoading ? (
                                            <div className="spinner" />
                                        ) : getInstructionLists().groupInstructions.length > 0 ? (
                                            <ul className="space-y-0">
                                                {getInstructionLists().groupInstructions
                                                    .filter(instruction => instruction.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                                    .map((instruction) => (
                                                        <li
                                                            key={instruction.instruction_id}
                                                            className={`
                                cursor-pointer 
                                text-zinc-700 
                                hover:bg-gray-100 
                                p-2 
                                ${instruction.instruction_id === selectedInstructionId ? 'bg-gray-200' : ''} 
                                border-b border-gray-200
                              `}
                                                            onClick={() => handleInstructionClick(instruction)}
                                                        >
                                                            <span>{instruction.name}</span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        ) : (
                                            <p className="text-center text-gray-500 py-4">No group instructions found</p>
                                        )}
                                    </div>
                                </div>

                                {/* Inherited Instructions */}
                                <div className="mt-8">
                                    <h2 className="text-lg font-semibold mb-2 text-gray-600">Inherited Instructions</h2>
                                    <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                                        {isLoading ? (
                                            <div className="spinner" />
                                        ) : getInstructionLists().inheritedInstructions.length > 0 ? (
                                            <ul className="space-y-0">
                                                {getInstructionLists().inheritedInstructions
                                                    .filter(instruction => instruction.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                                    .map((instruction) => (
                                                        <li
                                                            key={instruction.instruction_id}
                                                            className={`
                                cursor-pointer 
                                text-zinc-700 
                                hover:bg-gray-100 
                                p-2 
                                ${instruction.instruction_id === selectedInstructionId ? 'bg-gray-200' : ''} 
                                border-b border-gray-200
                                opacity-75
                              `}
                                                            onClick={() => handleInstructionClick(instruction)}
                                                        >
                                                            <div className="flex justify-between items-center">
                                                                <span>{instruction.name}</span>
                                                                <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded">
                                                                    Inherited
                                                                </span>
                                                            </div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        ) : (
                                            <p className="text-center text-gray-500 py-4">No inherited instructions</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Right Column - Form */}
                            <div className="w-2/3 px-4">
                                <div className="space-y-6">
                                    {/* Form fields */}
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                        <input
                                            type="text"
                                            name="instructionName"
                                            className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                            value={instructionName}
                                            onChange={(e) => setInstructionName(e.target.value)}
                                            disabled={!isEditable}
                                            required
                                        />
                                    </div>

                                    {/* Content */}
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Content</label>
                                        <textarea
                                            name="instructionContent"
                                            className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                            rows="8"
                                            placeholder="Enter content here"
                                            value={instructionContent}
                                            onChange={(e) => setInstructionContent(e.target.value)}
                                            disabled={!isEditable}
                                            required
                                        />
                                    </div>

                                    {/* Section */}
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Section</label>
                                        <select
                                            name="instructionSection"
                                            className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                            value={instructionSection}
                                            onChange={(e) => setInstructionSection(e.target.value)}
                                            disabled={!isEditable}
                                        >
                                            <option value="HPI">History of Present Illness</option>
                                            <option value="ROS">Review of Systems</option>
                                            <option value="PHYSICAL_EXAM">Physical Exam</option>
                                            <option value="EVALUATION">Evaluation and Plan</option>
                                        </select>
                                    </div>

                                    {/* Show inherited instruction message */}
                                    {isInherited && (
                                        <div className="bg-gray-50 border border-gray-200 rounded p-3 text-sm text-gray-600">
                                            This is an inherited instruction. It cannot be edited or deleted at this level.
                                        </div>
                                    )}

                                    {/* Action buttons */}
                                    {isEditable && selectedInstructionId && (
                                        <div className="flex justify-between mt-4">
                                            <button
                                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                                                onClick={handleDeleteClick}>
                                                Delete
                                            </button>
                                            <button
                                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                                onClick={handleSaveClick}
                                                disabled={!areFieldsFilled()}>
                                                Save
                                            </button>
                                        </div>
                                    )}

                                    {/* Show save button for new instructions */}
                                    {isEditable && !selectedInstructionId && (
                                        <div className="flex justify-end mt-4">
                                            <button
                                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                                onClick={handleSaveClick}
                                                disabled={!areFieldsFilled()}>
                                                Save
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showConfirmationDialog && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Deletion</h3>
                        <p className="text-gray-500 mb-6">Are you sure you want to delete this instruction? This action cannot be undone.</p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setShowConfirmationDialog(false)}
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDelete}
                                className="px-4 py-2 bg-red-600 border border-transparent rounded-md text-white hover:bg-red-700"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Save Confirmation Dialog */}
            {showSaveConfirmationDialog && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Group Instruction</h3>
                        <p className="text-gray-500 mb-6">
                            This instruction will be applied to all users in the group. Are you sure you want to continue?
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button
                                onClick={() => setShowSaveConfirmationDialog(false)}
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmSave}
                                className="px-4 py-2 bg-indigo-600 border border-transparent rounded-md text-white hover:bg-indigo-700"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default GroupCustomInstructions; 