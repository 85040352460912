import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';
import { useAuth } from './AuthProvider';

const SettingsContext = createContext(null);

export function SettingsProvider({ children }) {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo, isLoading: isAuthLoading } = useAuth();
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch settings when actorInfo is available
    useEffect(() => {
        if (actorInfo && !isAuthLoading) {
            fetchActorSettings();
        }
    }, [actorInfo, isAuthLoading]);


    const fetchActorSettings = useCallback(async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiSettings.endpoint}actor/all_settings`,
                { method: 'GET' }
            );

            // Ensure we have a valid response
            if (response && typeof response === 'object') {
                setSettings(response);
            } else {
                console.warn('Received invalid settings response:', response);
                setSettings({});
            }
        } catch (err) {
            console.error('Error fetching settings:', err);
            setError('Failed to load settings');
            // Set empty settings object on error
            setSettings({});
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently]);

    // Get a specific setting value
    const getSetting = useCallback((settingName) => {
        if (!settings || !settings[settingName] || !('value' in settings[settingName])) {
            return null;
        }
        return settings[settingName].value;
    }, [settings]);

    // Check if a setting is editable by the current user
    const isSettingEditable = useCallback((settingName) => {
        if (!settings || !settings[settingName]) {
            return false;
        }
        return settings[settingName].user_can_edit === true;
    }, [settings]);

    const value = {
        settings,
        isLoading,
        error,
        getSetting,
        isSettingEditable,
        refreshSettings: fetchActorSettings
    };

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}

export function useSettings() {
    const context = useContext(SettingsContext);
    if (context === null) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }
    return context;
} 