import { protectedResources, callProtectedApi } from '../authConfig';

export const templatesApi = {
    getTemplates: async (getAccessTokenSilently) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGetTemplates.endpoint,
            {
                method: 'POST'
            }
        );
    },

    upsertTemplate: async (getAccessTokenSilently, templateData) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiUpsertTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify(templateData)
            }
        );
    },

    deleteTemplate: async (getAccessTokenSilently, templateId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiDeleteTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ template_id: templateId })
            }
        );
    },

    generateTemplate: async (getAccessTokenSilently, description) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGenerateTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ description })
            }
        );
    },
}; 