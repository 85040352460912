import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Paper, Container, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAuth0 } from '@auth0/auth0-react';

const ErrorPage = () => {
  console.log('ErrorPage component rendering');
  
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const theme = useTheme();
  const [errorInfo, setErrorInfo] = useState({
    error: 'Oops! Something went wrong',
    errorDescription: 'Please return to the home page and try again.'
  });

  useEffect(() => {
    console.log('ErrorPage - useEffect running');
    
    // Parse error parameters from URL
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    
    console.log('ErrorPage - URL parameters:', { 
      error, 
      errorDescription, 
      fullUrl: window.location.href,
      search: location.search
    });
    
    // Only update error info if we have specific error details
    if (error || errorDescription) {
      setErrorInfo({
        error: error || 'Authentication Error',
        errorDescription: errorDescription || 'An error occurred during authentication.'
      });
      console.log('ErrorPage - Updated error info:', { error, errorDescription });
    }
  }, [location]);

  const handleReturnHome = () => {
    // Log the user out and redirect to the home page
    logout({ 
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  // Safely decode URI component
  const safeDecodeURIComponent = (str) => {
    if (!str) return '';
    try {
      return decodeURIComponent(str);
    } catch (e) {
      console.error('Failed to decode URI component:', str, e);
      return str;
    }
  };

  console.log('ErrorPage - Rendering with error info:', errorInfo);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          py: 4
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)'
          }}
        >
          <ErrorOutlineIcon color="error" sx={{ fontSize: 72, mb: 3 }} />
          
          <Typography variant="h4" component="h1" gutterBottom fontWeight="bold" textAlign="center" sx={{ mb: 2 }}>
            {errorInfo.error}
          </Typography>
          
          <Typography variant="body1" color="text.secondary" textAlign="center" sx={{ mb: 5 }}>
            {errorInfo.errorDescription ? safeDecodeURIComponent(errorInfo.errorDescription) : 'An unexpected error occurred.'}
          </Typography>
          
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              size="large"
              onClick={handleReturnHome}
              sx={{ 
                px: 4, 
                py: 1,
                backgroundColor: theme.palette.primary.main,
                transition: 'background-color 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: theme.palette.primary.light
                },
                fontWeight: 500
              }}
            >
              Log Out & Return Home
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ErrorPage; 