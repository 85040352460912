import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { templatesApi } from '../api/templatesApi';
import {
    PencilIcon,
    TrashIcon,
    DocumentTextIcon,
    DocumentDuplicateIcon
} from '@heroicons/react/24/outline';
import { Dialog, Popover } from '@headlessui/react';

export default function TemplatesSettings({ onSuccessfulSave }) {
    const { getAccessTokenSilently, user } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [allTemplates, setAllTemplates] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [customTemplates, setCustomTemplates] = useState([]);

    // -- Form states --
    const [templateName, setTemplateName] = useState('');
    const [promptTemplate, setPromptTemplate] = useState('');

    // -- State for AI generation mode --
    // "mode" can be "CREATE" or "GENERATE"
    const [mode, setMode] = useState('CREATE');
    const [generationPrompt, setGenerationPrompt] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [isViewing, setIsViewing] = useState(false);

    const [helpPopoverOpen, setHelpPopoverOpen] = useState(false);

    // Define fetchTemplates with useCallback
    const fetchTemplates = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await templatesApi.getTemplates(getAccessTokenSilently);
            const templatesArray = Object.entries(response.templates).map(([id, data]) => ({
                template_id: parseInt(id),
                ...data
            }));

            setAllTemplates(templatesArray);
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setIsLoading(false);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    useEffect(() => {
        const filtered = allTemplates.filter(t =>
            t.template_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            t.description?.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setDefaultTemplates(filtered.filter(t => t.who_can_view === 'GLOBAL'));
        setCustomTemplates(filtered.filter(t => t.who_can_view === 'ACTOR'));
    }, [searchQuery, allTemplates]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // We do NOT include "description" in the final upsert payload
            const templateData = {
                template_id: editingTemplate?.template_id,
                template_name: templateName,
                prompt_template: promptTemplate,
                // default to ACTOR if new, or keep existing
                who_can_view: editingTemplate?.who_can_view || 'ACTOR'
            };

            await templatesApi.upsertTemplate(getAccessTokenSilently, templateData);
            await fetchTemplates();
            closeModal();
            onSuccessfulSave('Template saved successfully');
        } catch (error) {
            console.error('Error saving template:', error);
        }
    };

    const handleDelete = async (templateId) => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            try {
                await templatesApi.deleteTemplate(getAccessTokenSilently, templateId);
                await fetchTemplates();
                onSuccessfulSave('Template deleted successfully');
            } catch (error) {
                console.error('Error deleting template:', error);
            }
        }
    };

    // Open modal for either new or existing template
    const openModal = (template = null) => {
        if (template) {
            setEditingTemplate(template);
            setTemplateName(template.template_name);
            setPromptTemplate(template.prompt_template);
            setMode('CREATE'); // For an existing template, start in "CREATE" mode
            setIsViewing(true); // Set viewing mode to true when editing/viewing
        } else {
            setEditingTemplate(null);
            setTemplateName('');
            setPromptTemplate('');
            setMode('CREATE'); // For a new template, default to "CREATE" mode
            setIsViewing(false); // Set viewing mode to false when creating new
        }
        // Reset generation states
        setGenerationPrompt('');
        setIsGenerating(false);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingTemplate(null);
        setTemplateName('');
        setPromptTemplate('');
        setGenerationPrompt('');
        setIsGenerating(false);
        setMode('CREATE');
        setIsViewing(false); // Reset viewing mode on close
    };

    // AI Template Generation
    const handleGenerate = async () => {
        setIsGenerating(true);
        try {
            const response = await templatesApi.generateTemplate(
                getAccessTokenSilently,
                generationPrompt
            );

            if (response.template_name && response.prompt_template) {
                // Populate fields with AI output
                setTemplateName(response.template_name);
                setPromptTemplate(response.prompt_template);
                // Switch mode so user sees the basic create form with these new values
                setMode('CREATE');
                setIsViewing(false); // Ensure viewing mode is off to allow editing
            } else {
                console.error('Failed to generate template: Missing required fields', response);
            }
        } catch (error) {
            console.error('Error generating template:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const TemplateList = ({ templates, title, emptyMessage }) => (
        <div className="space-y-4">
            <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
            {templates.length === 0 ? (
                <div className="text-center py-6 bg-gray-50 rounded-lg">
                    <DocumentDuplicateIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No templates</h3>
                    <p className="mt-1 text-sm text-gray-500">{emptyMessage}</p>
                </div>
            ) : (
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                    {templates.map((template) => (
                        <div
                            key={template.template_id}
                            className="relative flex flex-col bg-white p-4 rounded-lg shadow-sm ring-1 ring-gray-900/5 hover:shadow-md transition-shadow"
                        >
                            <div className="flex items-start gap-x-2 mb-3">
                                <DocumentTextIcon className="h-5 w-5 text-indigo-600 flex-shrink-0" />
                                <h3 className="text-sm font-semibold leading-5 text-gray-900 break-words">
                                    {template.template_name}
                                </h3>
                            </div>
                            <div className="mt-auto pt-3 flex flex-col gap-2">
                                {template.who_can_view === 'ACTOR' ? (
                                    <>
                                        <button
                                            onClick={() => openModal(template)}
                                            className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <PencilIcon className="h-4 w-4 mr-1" />
                                            Edit/View
                                        </button>
                                        <button
                                            onClick={() => handleDelete(template.template_id)}
                                            className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-red-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            <TrashIcon className="h-4 w-4 mr-1" />
                                            Delete
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => openModal(template)}
                                        className="inline-flex items-center justify-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        <DocumentTextIcon className="h-4 w-4 mr-1" />
                                        View
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-3xl font-semibold text-gray-900 flex items-center">
                            Document Templates
                            <Popover className="relative ml-2">
                                <Popover.Button 
                                    className="flex items-center justify-center h-6 w-6 rounded-full bg-gray-200 text-gray-600 text-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    onClick={() => setHelpPopoverOpen(!helpPopoverOpen)}
                                >
                                    ?
                                </Popover.Button>
                                
                                <Popover.Panel className="absolute z-10 mt-2 w-screen max-w-md bg-white shadow-lg rounded-xl border border-gray-200 p-4">
                                    <div className="space-y-4">
                                        <h3 className="text-lg font-semibold text-gray-900">Document Templates</h3>
                                        
                                        <div className="grid grid-cols-1 gap-6 mt-4">
                                            
                                            <div className="flex items-start space-x-3">
                                                <div className="flex-shrink-0 bg-indigo-100 rounded-lg p-2">
                                                    <DocumentTextIcon className="h-8 w-8 text-indigo-600" />
                                                </div>
                                                <div>
                                                    <h4 className="text-sm font-semibold text-gray-900">Create supplemental patient docs</h4>
                                                    <p className="mt-1 text-sm text-gray-600">
                                                        When you click on a patient from your dashboard and then click on "Supplemental Docs" you will be able to use your custom document templates to generate documents for that patient.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="flex items-start space-x-3">
                                                <div className="flex-shrink-0 bg-indigo-100 rounded-lg p-2">
                                                    <PencilIcon className="h-8 w-8 text-indigo-600" />
                                                </div>
                                                <div>
                                                    <h4 className="text-sm font-semibold text-gray-900">View and edit your templates</h4>
                                                    <p className="mt-1 text-sm text-gray-600">
                                                        Customize existing templates or create new ones from scratch. 
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            
                                            <div className="flex items-start space-x-3">
                                                <div className="flex-shrink-0 bg-indigo-100 rounded-lg p-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <h4 className="text-sm font-semibold text-gray-900">Generate templates with AI</h4>
                                                    <p className="mt-1 text-sm text-gray-600">
                                                        When you go to Add Template, you can generate a template with AI by first clicking on the option "Generate with AI" and then describing what you need.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Create and manage templates for generating supplemental documents from your notes.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button
                            type="button"
                            onClick={() => openModal()}
                            className="block rounded-md bg-indigo-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-800"
                        >
                            Add Template
                        </button>
                    </div>
                </div>

                <div className="mt-8">
                    <input
                        type="text"
                        placeholder="Search templates..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-6"
                    />

                    <div className="space-y-8">
                        <TemplateList
                            templates={customTemplates}
                            title="Custom Templates"
                            emptyMessage="Start by creating your first custom template"
                        />
                        <TemplateList
                            templates={defaultTemplates}
                            title="Default Templates"
                            emptyMessage="No default templates available"
                        />
                    </div>
                </div>
            </div>

            <Dialog open={isModalOpen} onClose={closeModal} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-3xl w-full rounded-xl bg-white p-6">
                        <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900 mb-4">
                            {editingTemplate ? 'Edit Template' : 'Create New Template'}
                        </Dialog.Title>

                        {/* If "GLOBAL", user cannot edit, so just show name/prompt read-only. */}
                        {editingTemplate?.who_can_view === 'GLOBAL' ? (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Template Name
                                    </label>
                                    <input
                                        type="text"
                                        value={templateName}
                                        readOnly
                                        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 text-gray-600 shadow-sm sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Prompt Template
                                    </label>
                                    <textarea
                                        value={promptTemplate}
                                        rows={10}
                                        readOnly
                                        className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 text-gray-600 shadow-sm sm:text-sm font-mono"
                                    />
                                </div>

                                <div className="mt-6 flex justify-end">
                                    <button
                                        type="button"
                                        onClick={closeModal}
                                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {!isViewing && (
                                    <div className="flex gap-4 items-center mb-4">
                                        <button
                                            type="button"
                                            className={`px-3 py-1 rounded-md text-sm font-medium ${
                                                mode === 'CREATE'
                                                    ? 'bg-indigo-100 text-indigo-900'
                                                    : 'bg-gray-100 text-gray-800'
                                            }`}
                                            onClick={() => setMode('CREATE')}
                                        >
                                            Create from Scratch
                                        </button>
                                        <button
                                            type="button"
                                            className={`px-3 py-1 rounded-md text-sm font-medium flex items-center ${
                                                mode === 'GENERATE'
                                                    ? 'bg-indigo-100 text-indigo-900'
                                                    : 'bg-gray-100 text-gray-800'
                                            }`}
                                            onClick={() => setMode('GENERATE')}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-6 text-indigo-600 pr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                            </svg>
                                            Generate with AI
                                            
                                        </button>
                                    </div>
                                )}

                                {mode === 'GENERATE' ? (
                                    <div className="space-y-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                            Describe the template you want to generate
                                        </label>
                                        <textarea
                                            rows={4}
                                            value={generationPrompt}
                                            onChange={(e) => setGenerationPrompt(e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            placeholder="E.g. a discharge summary template for elderly patients..."
                                        />
                                        <div className="flex justify-end">
                                            <button
                                                type="button"
                                                onClick={handleGenerate}
                                                disabled={isGenerating || !generationPrompt.trim()}
                                                className="rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 disabled:bg-gray-300"
                                            >
                                                {isGenerating ? 'Generating...' : 'Generate'}
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    // CREATE MODE: show name + prompt fields, plus Save
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Template Name
                                            </label>
                                            <input
                                                type="text"
                                                value={templateName}
                                                onChange={(e) => setTemplateName(e.target.value)}
                                                required
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Prompt Template
                                            </label>
                                            <textarea
                                                rows={12}
                                                value={promptTemplate}
                                                onChange={(e) => setPromptTemplate(e.target.value)}
                                                required
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-mono"
                                                placeholder="Enter your prompt template here..."
                                            />
                                        </div>

                                        <div className="mt-6 flex justify-end gap-x-3">
                                            <button
                                                type="button"
                                                onClick={closeModal}
                                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="submit"
                                                className="rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800"
                                            >
                                                {editingTemplate ? 'Save Changes' : 'Create Template'}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </>
                        )}
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    );
} 