import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { supplementalDocumentsApi } from '../api/supplementalDocumentsApi';
import { templatesApi } from '../api/templatesApi';
import {
    DocumentTextIcon,
    DocumentPlusIcon,
    ArrowPathIcon,
    ClipboardDocumentIcon,
    ChevronLeftIcon,
    PencilIcon,
    TrashIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export default function SupplementalDocumentsModal({ isOpen, onClose, documentId, onSuccess }) {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const [supplementalDocuments, setSupplementalDocuments] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [showTemplateSelect, setShowTemplateSelect] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingContent, setEditingContent] = useState('');
    const [localDocuments, setLocalDocuments] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchData();
        }
    }, [isOpen]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const [docsResponse, templatesResponse] = await Promise.all([
                supplementalDocumentsApi.getSupplementalDocuments(getAccessTokenSilently, documentId),
                templatesApi.getTemplates(getAccessTokenSilently)
            ]);

            const supplementalDocsArray = docsResponse?.supplemental_documents
                ? Object.entries(docsResponse.supplemental_documents)
                    .map(([id, data]) => ({
                        supplemental_document_id: parseInt(id),
                        ...data
                    }))
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                : [];

            const processedDocs = addDocumentNumbering(supplementalDocsArray);
            setSupplementalDocuments(processedDocs);

            if (processedDocs.length === 0) {
                setShowTemplateSelect(true);
            } else if (!selectedDocument) {
                setSelectedDocument(processedDocs[0]);
            }

            const templatesArray = templatesResponse?.templates
                ? Object.entries(templatesResponse.templates)
                    .map(([id, data]) => ({
                        template_id: parseInt(id),
                        ...data
                    }))
                : [];

            setTemplates(templatesArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const addDocumentNumbering = (docs) => {
        // First, sort by date to ensure consistent ordering (newest first)
        const sortedDocs = [...docs].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        // Count occurrences of each template name from oldest to newest
        const templateCounts = {};
        [...sortedDocs].reverse().forEach(doc => {
            const baseName = doc.document_name;
            templateCounts[baseName] = (templateCounts[baseName] || 0) + 1;
        });

        // Apply numbering from newest to oldest
        return sortedDocs.map(doc => {
            const baseName = doc.document_name;
            const count = templateCounts[baseName];

            if (count > 1) {
                templateCounts[baseName] = count - 1;
                return {
                    ...doc,
                    display_name: count > 1 ? `${baseName} (${count - 1})` : baseName
                };
            }

            return {
                ...doc,
                display_name: baseName
            };
        });
    };

    const handleNewDocumentClick = () => {
        setSelectedDocument(null);
        setShowTemplateSelect(true);
    };

    const handleDocumentClick = (doc) => {
        setSelectedDocument(doc);
        setShowTemplateSelect(false);
    };

    const handleGenerateDocument = async () => {
        if (!selectedTemplateId) return;

        setIsGenerating(true);
        try {
            const result = await supplementalDocumentsApi.generateSupplementalDocument(
                getAccessTokenSilently,
                documentId,
                selectedTemplateId
            );

            // Create new document object from the response
            const newDocument = {
                supplemental_document_id: result.supplemental_document_id,
                content: result.content,
                created_at: result.created_at,
                document_name: result.document_name,
                display_name: result.document_name // Initial display name before numbering
            };

            // Update the documents list by adding the new document
            setSupplementalDocuments(prevDocs => {
                const updatedDocs = [newDocument, ...prevDocs];
                const numberedDocs = addDocumentNumbering(updatedDocs);
                // Find and set the newly created document with proper numbering
                const numberedNewDoc = numberedDocs.find(
                    doc => doc.supplemental_document_id === newDocument.supplemental_document_id
                );
                setSelectedDocument(numberedNewDoc);
                return numberedDocs;
            });

            setShowTemplateSelect(false);
            setSelectedTemplateId(null);
            onSuccess?.('Supplemental document generated successfully');
        } catch (error) {
            console.error('Error generating document:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const copyToClipboard = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
            setCopiedToClipboard(true);
            setTimeout(() => setCopiedToClipboard(false), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const handleDelete = async (docId) => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await supplementalDocumentsApi.deleteSupplementalDocument(
                    getAccessTokenSilently,
                    docId
                );
                await fetchData();
                if (selectedDocument?.supplemental_document_id === docId) {
                    setSelectedDocument(null);
                }
                onSuccess?.('Document deleted successfully');
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    };

    const handleContentChange = (newContent) => {
        setSupplementalDocuments(prevDocs =>
            prevDocs.map(doc =>
                doc.supplemental_document_id === selectedDocument.supplemental_document_id
                    ? { ...doc, content: newContent }
                    : doc
            )
        );
        setSelectedDocument(prev => ({ ...prev, content: newContent }));
    };

    const handleSave = async (docId, content) => {
        setIsSaving(true);
        try {
            await supplementalDocumentsApi.updateSupplementalDocument(
                getAccessTokenSilently,
                docId,
                content
            );
            onSuccess?.('Document saved successfully');
        } catch (error) {
            console.error('Error saving document:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleNavigateToTemplateSettings = () => {
        onClose(); // Close the modal
        navigate('/settings/templates'); // Navigate to template settings
    };

    const renderDocumentList = () => {
        if (supplementalDocuments.length === 0) {
            return (
                <div className="flex items-center justify-center h-full text-gray-500">
                    No documents generated
                </div>
            );
        }

        return (
            <ul className="divide-y divide-gray-200">
                {supplementalDocuments.map((doc) => (
                    <li
                        key={doc.supplemental_document_id}
                        className={`cursor-pointer hover:bg-gray-50 ${selectedDocument?.supplemental_document_id === doc.supplemental_document_id
                            ? 'bg-indigo-50'
                            : ''
                            }`}
                        onClick={() => handleDocumentClick(doc)}
                    >
                        <div className="px-4 py-3">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-3">
                                    <DocumentTextIcon className="h-5 w-5 text-gray-400" />
                                    <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate">
                                            {doc.display_name}
                                        </p>
                                        <p className="text-xs text-gray-500">
                                            {format(new Date(doc.created_at), 'MMM d, h:mm a')}
                                        </p>
                                    </div>
                                </div>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDelete(doc.supplemental_document_id);
                                    }}
                                    className="text-gray-400 hover:text-red-600"
                                    title="Delete document"
                                >
                                    <TrashIcon className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const renderMainContent = () => {
        if (isLoading) {
            return (
                <div className="flex flex-col items-center justify-center h-full text-center px-4">
                    <ArrowPathIcon className="h-12 w-12 text-gray-400 animate-spin mb-4" />
                    <h3 className="text-lg font-medium text-gray-900">
                        Loading templates...
                    </h3>
                </div>
            );
        }

        if (showTemplateSelect || supplementalDocuments.length === 0) {
            if (templates.length === 0) {
                return (
                    <div className="flex flex-col items-center justify-center h-full text-center px-4">
                        <DocumentPlusIcon className="h-12 w-12 text-gray-400 mb-4" />
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                            No templates available
                        </h3>
                        <p className="text-sm text-gray-500 mb-6">
                            Create your first template to get started with supplemental documents
                        </p>
                        <button
                            onClick={handleNavigateToTemplateSettings}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-900 hover:bg-indigo-800"
                        >
                            Create Template
                        </button>
                    </div>
                );
            }

            // Split templates into default and custom
            const defaultTemplates = templates.filter(template => template.who_can_view !== 'ACTOR');
            const customTemplates = templates.filter(template => template.who_can_view === 'ACTOR');

            return (
                <div className="flex flex-col h-full">
                    <div className="flex-1 overflow-y-auto px-4">

                        {/* Custom Templates Section */}
                        {customTemplates.length > 0 && (
                            <div className="mb-8">
                                <h3 className="text-sm font-medium text-gray-500 mb-4">My Templates</h3>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                                    {customTemplates.map((template) => (
                                        <button
                                            key={template.template_id}
                                            onClick={() => setSelectedTemplateId(template.template_id)}
                                            className={`relative flex flex-col p-4 rounded-lg border text-left ${selectedTemplateId === template.template_id
                                                ? 'border-indigo-600 ring-2 ring-indigo-600'
                                                : 'border-gray-300 hover:border-indigo-400'
                                                }`}
                                        >
                                            <h4 className="text-sm font-medium text-gray-900 text-left">
                                                {template.template_name}
                                            </h4>
                                            {template.description && (
                                                <p className="mt-1 text-xs text-gray-500 text-left">
                                                    {template.description}
                                                </p>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}

                        {/* Default Templates Section */}
                        {defaultTemplates.length > 0 && (
                            <div className="mb-8">
                                <h3 className="text-sm font-medium text-gray-500 mb-4">Default Templates</h3>
                                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                                    {defaultTemplates.map((template) => (
                                        <button
                                            key={template.template_id}
                                            onClick={() => setSelectedTemplateId(template.template_id)}
                                            className={`relative flex flex-col p-4 rounded-lg border text-left ${selectedTemplateId === template.template_id
                                                ? 'border-indigo-600 ring-2 ring-indigo-600'
                                                : 'border-gray-300 hover:border-indigo-400'
                                                }`}
                                        >
                                            <h4 className="text-sm font-medium text-gray-900 text-left">
                                                {template.template_name}
                                            </h4>
                                            {template.description && (
                                                <p className="mt-1 text-xs text-gray-500 text-left">
                                                    {template.description}
                                                </p>
                                            )}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}


                    </div>

                    {/* Footer with actions */}
                    <div className="border-t border-gray-200 p-4 bg-gray-50">
                        <div className="flex justify-between items-center">
                            <button
                                onClick={handleNavigateToTemplateSettings}
                                className="inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-indigo-900 hover:text-indigo-800"
                            >
                                Manage Templates
                            </button>
                            {selectedTemplateId && (
                                <button
                                    onClick={handleGenerateDocument}
                                    disabled={isGenerating}
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-900 hover:bg-indigo-800 disabled:opacity-50"
                                >
                                    {isGenerating ? (
                                        <>
                                            <ArrowPathIcon className="animate-spin h-5 w-5 mr-2" />
                                            Generating...
                                        </>
                                    ) : (
                                        'Generate Document'
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        if (selectedDocument) {
            return (
                <div className="flex flex-col h-full">
                    <textarea
                        value={selectedDocument.content}
                        onChange={(e) => handleContentChange(e.target.value)}
                        onBlur={() => handleSave(selectedDocument.supplemental_document_id, selectedDocument.content)}
                        className="flex-1 w-full p-4 font-mono text-sm text-gray-700 bg-gray-50 rounded-lg border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 mb-4"
                        placeholder="Start typing..."
                    />
                    <button
                        onClick={() => copyToClipboard(selectedDocument.content)}
                        className="self-end inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        <ClipboardDocumentIcon className="h-5 w-5 mr-2" />
                        {copiedToClipboard ? 'Copied!' : 'Copy'}
                    </button>
                </div>
            );
        }

        return (
            <div className="flex items-center justify-center h-full text-gray-500">
                Select a document to view
            </div>
        );
    };

    const renderSidebar = () => (
        <div className="w-64 border-r border-gray-200 flex flex-col">
            {/* Header with New Document button */}
            <div className="p-4 h-[72px] border-b border-gray-200">
                <button
                    onClick={handleNewDocumentClick}
                    className="w-full inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-900 hover:bg-indigo-800"
                >
                    <DocumentPlusIcon className="h-5 w-5 mr-2" />
                    New Document
                </button>
            </div>

            {/* Document List */}
            <div className="flex-1 overflow-y-auto">
                {isLoading ? (
                    <div className="flex items-center justify-center h-full">
                        <ArrowPathIcon className="h-6 w-6 text-gray-400 animate-spin" />
                    </div>
                ) : (
                    renderDocumentList()
                )}
            </div>
        </div>
    );

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="mx-auto max-w-5xl w-full rounded-xl bg-white relative">
                    {/* Close button */}
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                    >
                        <XMarkIcon className="h-6 w-6" />
                    </button>

                    <div className="flex h-[80vh]">
                        {renderSidebar()}

                        {/* Main Content Area */}
                        <div className="flex-1 flex flex-col">
                            {/* Main Content Header */}
                            <div className="p-4 h-[72px] border-b border-gray-200">
                                <div className="flex items-center h-full">
                                    <h2 className="text-lg font-semibold text-gray-900 truncate">
                                        {showTemplateSelect ? 'Select a Template' : (selectedDocument?.document_name || 'No document selected')}
                                    </h2>
                                </div>
                            </div>

                            {/* Main Content Body */}
                            <div className="flex-1 overflow-y-auto p-4">
                                {renderMainContent()}
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
} 